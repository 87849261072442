

//localStorage.theme

const getTheme = () => {
  // default to light
  if (!localStorage.getItem('theme')) {
    localStorage.setItem('theme', 'light')
  }

  let theme = localStorage.getItem('theme')
  return theme
}

const setTheme = (value) => {
  if (value) {
    localStorage.setItem('theme', value)
  }
}


const storeToken = (value) => {
  if (value) {
    const { access, refresh } = value
    localStorage.setItem('access_token', access)
    localStorage.setItem('refresh_token', refresh)
  }
}


const getToken = () => {
  let access_token = localStorage.getItem('access_token')
  let refresh_token = localStorage.getItem('refresh_token')
  return { access_token, refresh_token }
}

const removeToken = () => {
  localStorage.removeItem('access_token')
  localStorage.removeItem('refresh_token')
}


const storeUserInfo = (value) => {
  if (value) {
    const { username, full_name, date_of_birth, gender, religion, email } = value;
    const userInfo = {
      username,
      full_name,
      date_of_birth,
      gender,
      religion,
      email
    };

    // Store the user object as a JSON string in localStorage
    localStorage.setItem('user', JSON.stringify(userInfo));
  }
};

const getUserInfo = () => {
  const storedUser = localStorage.getItem('user');
  if (storedUser) {
    return JSON.parse(storedUser);
  }
  return null; // or handle the case when no user is stored
};

const removeUserInfo = () => {
  localStorage.removeItem('user')
}


export { storeToken, getToken, removeToken, getTheme, setTheme, storeUserInfo, getUserInfo, removeUserInfo,  }