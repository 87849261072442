import Modal from "./Modal"
import useCreatePost from "../hooks/useCreatePost"
import React, { useEffect, useState } from 'react';
import "../assets/css/create-post.css";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import axiosRetry from 'axios-retry';
import { useTranslation } from "react-i18next";


const CreatePostModal = () => {
  const useCreatePostModal = useCreatePost()
  const {t} = useTranslation('create-post')
  const translation = t('create-post')
  const localUsername = JSON.parse(localStorage.getItem('user'));


  // States
  const [currentState, setCurrentStep] = useState(0);
    
  const [seekerVideoMessage, setSeekerVideoMessage] = useState('');
  const [seekerVideoInProcess, setSeekerVideoInProcess] = useState(false);
  const [placeVideoInProcess, setPlaceVideoInProcess] = useState(false);
  const [docInProcess, setDocInProcess] = useState(false);
  const [placeVideoMessage, setPlaceVideoMessage] = useState('');
  const [postDataErrorMessage, setPostDataErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [VideoObject, setVideoObject] = useState(0);
  const [postSlug, setPostSlug] = useState('');


  const [electricBillFile, setElectricBillFile] = useState(null);
  const [cnicFile, setCnicFile] = useState(null);
  const [electricBillPreview, setElectricBillPreview] = useState(null);
  const [cnicPreview, setCnicPreview] = useState(null);
  
  // UI state
  const [electricBillMessage, setElectricBillMessage] = useState('');
  const [cnicMessage, setCnicMessage] = useState('');
  


  const navigate = useNavigate();
  axiosRetry(axios, { retries: 3 }); 


  const [formData, setFormData] = useState({
    post_type: '',
    need: '',
    seeker: '',
    description: '',
    address: '',
    phone_number: '',
    seeker_cnic: "",
    needed_money: 0,
  });


  const [bankFormData, setBankFormData] = useState({
    bank_name: '',
    account_number: '',
    account_holder: '',
    slug: '',
  });

  const [showBankDetailFields, setShowBankDetailFields] = useState(true)

  useEffect (() => {
    setBankFormData((prevData) => ({
      ...prevData,
      slug: postSlug,
    }));

  }, [postSlug])

  const handlePostDataChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: val,
    }));
  };

  const handleBankDataChange = (e) => {
    setBankFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.value,
    }));
  };
    

  const handleSeekerFileChange = async (e) => {
    const file = e.target.files[0];
    
    if (!file) {
      setSeekerVideoMessage('No file selected.');
      return;
    }
  
    if (file.size > 500 * 1024 * 1024) {
      setSeekerVideoMessage(`${translation.large_file_size_error}`);
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file);
    formData.append('VideoObject', VideoObject);
    formData.append('run_ai', "False");
    formData.append('slug', postSlug);

  
    setSeekerVideoInProcess(true);
    setSeekerVideoMessage(`${translation.ai_verifing_mess}`);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/post/upload-seeker-videos/`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'multipart/form-data',
          },
          timeout: 500000,
        }, 
      );
  
      if (response.status === 200) {
        setCurrentStep(4);
        setVideoObject(response.data.VideoObject)
        setSeekerVideoMessage(response.data.message);
        setSeekerVideoInProcess(false)
        setPostDataErrorMessage("")

      } else {
        setSeekerVideoMessage(response.data.error || 'An unexpected error occurred');
      }
    } catch (error) {
      if (error.response) {
        const errorMsg =error.response.data.error || `Error ${error.response.status}: ${error.response.statusText}`;
        setSeekerVideoMessage(errorMsg);
      } else {
        setSeekerVideoMessage('Network error occurred while uploading the seeker video.');
      }
    } finally {
      setSeekerVideoInProcess(false);
    }
    
  };

  const handlePlaceFileChange = async (e) => {
    const file = e.target.files[0];
    
    if (!file) {
      setPlaceVideoMessage('No file selected.');
      return;
    }
  
    if (file.size > 500 * 1024 * 1024) {
      setPlaceVideoMessage(`${translation.large_file_size_error}`);
      return;
    }
  
    const formData = new FormData();
    formData.append('file', file,);
    formData.append('VideoObject', VideoObject);
    formData.append('run_ai', "False");
    formData.append('slug', postSlug);

    setPlaceVideoInProcess(true)
    setPlaceVideoMessage(`${translation.ai_verifing_mess}`);

    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_DOMAIN + '/post/upload-place-videos/', formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        setCurrentStep(5);
        setVideoObject(response.data.VideoObject)
        setPlaceVideoMessage(response.data.message);
        setPlaceVideoInProcess(false)
        setPostDataErrorMessage("")


      } else {
        setPlaceVideoInProcess(false)
        setPlaceVideoMessage(response.data.error || `${translation.unexpected_error_message}`);
      }

    } catch (error) {
      setPlaceVideoInProcess(false);
      if (error.response && error.response.status === 400) {
        setPlaceVideoMessage(error.response.data.error || `${translation.unexpected_error_message}`);
      } else {
        setPlaceVideoMessage('An error occurred while uploading the place video');
      }
    }finally {
      setSeekerVideoInProcess(false);
    }
  };

  // Allowed file types
  const allowedTypes = [
    // Documents
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'text/plain',
    // Images
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/bmp',
    'image/webp',
    'image/heic',
    'image/heif'
  ];
  
  // Handle electric bill file selection
  const handleElectricBillSelection = (e) => {
    const file = e.target.files[0];
    
    if (!file) {
      setElectricBillMessage('No file selected.');
      setElectricBillFile(null);
      setElectricBillPreview(null);
      return;
    }
    
    // Validate file size and type
    if (file.size > 100 * 1024 * 1024) {
      setElectricBillMessage(`File size for ${file.name} exceeds the 100MB limit.`);
      setElectricBillFile(null);
      setElectricBillPreview(null);
      return;
    }
    
    if (!allowedTypes.includes(file.type)) {
      setElectricBillMessage(`File type for ${file.name} is not allowed. Please upload only documents or images.`);
      setElectricBillFile(null);
      setElectricBillPreview(null);
      return;
    }
    
    // Create file preview
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setElectricBillPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      // For non-image files, just show filename
      setElectricBillPreview(file.name);
    }
    
    setElectricBillFile(file);
    setElectricBillMessage('File selected and ready for upload.');
  };
  
  // Handle CNIC file selection
  const handleCnicSelection = (e) => {
    const file = e.target.files[0];
    
    if (!file) {
      setCnicMessage('No file selected.');
      setCnicFile(null);
      setCnicPreview(null);
      return;
    }
    
    // Validate file size and type
    if (file.size > 100 * 1024 * 1024) {
      setCnicMessage(`File size for ${file.name} exceeds the 100MB limit.`);
      setCnicFile(null);
      setCnicPreview(null);
      return;
    }
    
    if (!allowedTypes.includes(file.type)) {
      setCnicMessage(`File type for ${file.name} is not allowed. Please upload only documents or images.`);
      setCnicFile(null);
      setCnicPreview(null);
      return;
    }
    
    // Create file preview
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setCnicPreview(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      // For non-image files, just show filename
      setCnicPreview(file.name);
    }
    
    setCnicFile(file);
    setCnicMessage('File selected and ready for upload.');
  };
  
  // Cancel electric bill file
  const cancelElectricBill = () => {
    setElectricBillFile(null);
    setElectricBillPreview(null);
    setElectricBillMessage('Electric bill file removed.');
    // Reset the file input
    document.getElementById('electric_bill').value = '';
  };
  
  // Cancel CNIC file
  const cancelCnic = () => {
    setCnicFile(null);
    setCnicPreview(null);
    setCnicMessage('CNIC file removed.');
    // Reset the file input
    document.getElementById('seeker_cnic').value = '';
  };
  
  // Handle form submission
  const handleFileSubmit = async (e) => {
    e.preventDefault();
    
    if (!electricBillFile || !cnicFile) {
      setPostDataErrorMessage('Please select both electric bill and CNIC files.');
      return;
    }
    
    setDocInProcess(true);
    setIsLoading(true);
    setElectricBillMessage('Uploading documents...');
    setCnicMessage('Uploading documents...');
    
    const formData = new FormData();
    formData.append('slug', postSlug);
    
    // Add files to FormData
    // The backend will receive these as a list of files
    formData.append('files', electricBillFile);
    formData.append('files', cnicFile);
    
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/post/upload-post-docs/`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      
      if (response.status === 200) {
        setElectricBillMessage('Documents uploaded successfully.');
        setCnicMessage('Documents uploaded successfully.');
        setPostDataErrorMessage('');
        setElectricBillMessage('');
        setCnicMessage('');
        setCurrentStep(6)
      } else {
        setElectricBillMessage(`${translation.unexpected_error_message}`);
        setCnicMessage(`${translation.unexpected_error_message}`);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        
        if (errorData?.error && Array.isArray(errorData.error.error)) {
          setPostDataErrorMessage(errorData.error.error[0]);
        } else if (errorData?.error.documents) {
          setPostDataErrorMessage("documents: " + errorData.error.documents[0]);
        } else if (typeof errorData?.error === "string") {
          setPostDataErrorMessage(errorData.error);
        } else {
          setPostDataErrorMessage(`${translation.unexpected_error_message}`);
        }
      } else {
        setPostDataErrorMessage(`${translation.unexpected_error_message}`);
      }
    } finally {
      setDocInProcess(false);
      setIsLoading(false);
    }
  };


  const SubmitPostData = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    const jsonData = JSON.stringify(formData);
  
    // Create post
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      }
    };

    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_DOMAIN +'/post/create-post/', jsonData, config);
      setIsLoading(false)

      if (response.status === 201) {
        setPostSlug(response.data.slug);
        setCurrentStep(2);
        setPostDataErrorMessage("")
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data) {
        const errorData = error.response.data;

        if (errorData?.error?.non_field_errors && Array.isArray(errorData?.error?.non_field_errors)){
          setPostDataErrorMessage(errorData.error.non_field_errors[0]); 
        }
        else if (errorData?.detail) {
          setPostDataErrorMessage(errorData?.detail); 
        } 
        else if (errorData?.error && Array.isArray(errorData?.error?.error)) {
          setPostDataErrorMessage(errorData.error.error[0]); 
        } 
        else if (errorData.error?.seeker){
          setPostDataErrorMessage(`${translation.seeker_name}: ` + errorData.error.seeker[0]); 
        } 
        else if (errorData.error?.address){
          setPostDataErrorMessage(`${translation.address}: ` + errorData.error.address[0]); 
        } 
        else if (errorData.error?.phone_number){
          setPostDataErrorMessage(`${translation.phone_number}: ` + errorData.error.phone_number[0]); 
        } 
        else if (errorData.error?.seeker_cnic){
          setPostDataErrorMessage(`${translation.seeker_cnic}: ` + errorData.error.seeker_cnic[0]); 
        } 
        else if (errorData.error?.needed_money){
          setPostDataErrorMessage(`${translation.money_need}: ` + errorData.error.needed_money[0]); 
        } 
        else if (errorData.error?.post_type){
          setPostDataErrorMessage(`${translation.asking_for}: ` + errorData.error.post_type[0]); 
        } 
        else if (errorData.error?.need){
          setPostDataErrorMessage(`${translation.need}: `+ errorData.error.need[0]); 
        } 
        else if (errorData.error?.description){
          setPostDataErrorMessage(`${translation.seeker_situation}: ` + errorData.error.description[0]); 
        } 
        else if (typeof errorData.error === "string") {
          setPostDataErrorMessage(errorData.error);
        } else {
          setPostDataErrorMessage(`${translation.unexpected_error_message}`);
        }
      } else {
        setPostDataErrorMessage(`${translation.unexpected_error_message}`);
      };
    };
  };

  const SubmitPostBankData = async (e) => {
    e.preventDefault();
    setIsLoading(true)

    if (!showBankDetailFields) {
      setCurrentStep(3);
      setPostDataErrorMessage("");
      setIsLoading(false);
      return; 
    }

    const jsonData = JSON.stringify(bankFormData);
  
    // Create post
    const config = {
      headers: {
        'Content-Type': 'application/json', // Use JSON content type
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      }
    };

    try {
      const response = await axios.post(process.env.REACT_APP_BACKEND_DOMAIN +'/post/create-post-bank-details/', jsonData, config);
      setIsLoading(false)

      if (response.status === 201) {
        setCurrentStep(3);
        setPostDataErrorMessage("")
      }
    } catch (error) {

      setIsLoading(false);
      if (error.response && error.response.data) {
        const errorData = error.response.data;

        if (errorData?.error && Array.isArray(errorData.error.error)) {
          setPostDataErrorMessage(errorData.error.error[0]); 
        } 
        else if (errorData?.error.account_number){
          setPostDataErrorMessage(`${translation.bank_details.account_number}: `+ errorData.error.account_number[0]); 
        } 
        else if (errorData?.error.account_holder){
          setPostDataErrorMessage(`${translation.bank_details.account_holder_name}: ` + errorData.error.account_holder[0]); 
        } 
        else if (errorData?.error.bank_name){
          setPostDataErrorMessage(`${translation.bank_details.bank_name}: ` + errorData.error.bank_name[0]); 
        } 
        else if (typeof errorData?.error === "string") {
          setPostDataErrorMessage(errorData.error);
        } else {
          setPostDataErrorMessage(`${translation.unexpected_error_message}`);
        }
      }else {
        setPostDataErrorMessage(`${translation.unexpected_error_message}`);
      };
    };
  };

  const viewPost = async (e) => {
    useCreatePostModal.close();
    navigate(`/detail/${postSlug}`)
  } 


  const content = (
    <>
        {currentState === 0 ? (
          <>
            <h2 className="mb-8 text-2xl font-bold text-gray-800 dark:text-white/90">
              {translation.create_post_info}
            </h2>

            <div className="grid gap-3">
              <div className="group relative overflow-hidden rounded-xl bg-teal-50 p-6 shadow-lg transition-all hover:shadow-xl dark:bg-teal-900/30">
                <div className="absolute -right-6 -top-6 text-9xl text-teal-100 opacity-50 transition-opacity group-hover:opacity-70 dark:text-teal-800">
                  <i className="fas fa-user-edit"></i>
                </div>
                <div className="relative">
                  <div className="mb-4 flex items-center gap-3">
                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-teal-600 text-white">
                      <i className="fas fa-user text-lg"></i>
                    </div>
                    <h3 className="text-lg font-semibold text-gray-800 dark:text-white/90">
                      {localUsername.full_name}, {translation.info_creator}
                    </h3>
                  </div>
                  <ol className="space-y-3 pl-1 text-gray-700 dark:text-white/80">
                    {translation.info_creator_points.map((mess, index) => (
                      <li key={index} className="flex items-start ">
                        <span className="mr-3 mt-1 inline-block h-2 w-2 shrink-0 rounded-full bg-teal-600"></span>
                        <span className="text-justify">{mess}</span>
                      </li>
                    ))}
                  </ol>
                </div>
              </div>

              <div className="group relative overflow-hidden rounded-xl bg-yellow-50 p-6 shadow-lg transition-all hover:shadow-xl dark:bg-yellow-900/30">
                <div className="absolute -right-6 -top-6 text-9xl text-yellow-100 opacity-50 transition-opacity group-hover:opacity-70 dark:text-yellow-800">
                  <i className="fas fa-clipboard-list"></i>
                </div>
                <div className="relative">
                  <div className="mb-4 flex items-center gap-3">
                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-yellow-600 text-white">
                      <i className="fas fa-exclamation-circle text-lg"></i>
                    </div>
                    <h3 className="text-xl font-semibold text-gray-800 dark:text-white/90">
                      {translation.info_required_message}
                    </h3>
                  </div>
                  <ol className="space-y-3 pl-1 text-gray-700 dark:text-white/80">
                    {translation.info_required_data.map((mess, index) => (
                      <li key={index} className="flex items-start">
                        <span className="mr-3 mt-1 inline-block h-2 w-2 shrink-0 rounded-full bg-yellow-600"></span>
                        <span className="text-justify">{mess}</span>
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            </div>

            <div className="text-center">

              <button
                onClick={()=>{setCurrentStep(1)}}
                className="w-full rounded-md bg-blue-500 text-white font-extrabold px-4 py-2 hover:bg-blue-600"
              >
                {translation.start_creating_post}
              </button>

            </div>

          </>
        ) : currentState === 1 ? (
          <>
            <h2 className="mb-6 text-2xl dark:text-white">{translation.heading1}</h2>            
            <div className="pt-2 pb-5 space-y-5 ">
    
              <div className="flex flex-col">
                <label htmlFor="seeker" className="block font-semibold mb-2 dark:text-white">{translation.seeker_name}</label>
                <input
                  type="text"
                  id="seeker"
                  name="seeker"
                  placeholder={translation.seeker_name_ph}
                  value={formData.seeker}
                  onChange={handlePostDataChange}
                  className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="address" className="block font-semibold mb-2 dark:text-white">{translation.address}</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  placeholder={translation.address_ph}
                  value={formData.address}
                  onChange={handlePostDataChange}
                  className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="phone_number" className="block font-semibold mb-2 dark:text-white">{translation.phone_number}</label>
                <input
                  type="tel"
                  id="phone_number"
                  name="phone_number"
                  placeholder={translation.phone_number_ph}
                  value={formData.phone_number}
                  onKeyDown={(e) => {
                    if (!/[0-9]/.test(e.key) && 
                        !["Backspace", "Delete", "Tab", "Enter", "ArrowLeft", "ArrowRight"].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  min="0"
                  step="1"
                  maxLength={11}

                  inputMode="numeric"
                  onChange={handlePostDataChange}
                  className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="seeker_cnic" className="block font-semibold mb-2 dark:text-white">{translation.seeker_cnic}</label>
                <input
                  type="tel"
                  id="seeker_cnic"
                  name="seeker_cnic"
                  placeholder={translation.seeker_cnic_ph}
                  value={formData.seeker_cnic}
                  onKeyDown={(e) => {
                    if (!/[0-9]/.test(e.key) && 
                        !["Backspace", "Delete", "Tab", "Enter", "ArrowLeft", "ArrowRight"].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  min="0"
                  step="1"
                  maxLength={15}
                  onChange={handlePostDataChange}
                  className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                  required
                />
              </div>

              <div className="flex flex-col">
                <label htmlFor="post_type" className="block font-semibold mb-2 dark:text-white">{translation.asking_for}</label>
                <select
                  id="post_type"
                  name="post_type"
                  value={formData.post_type}
                  onChange={handlePostDataChange}
                  className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                  required
                >
                  <option value="">{translation.purpose_detail.select_purpose}</option>
                  <option value="family">{translation.purpose_detail.asking_for_other_fam}</option>
                  <option value="person">{translation.purpose_detail.asking_for_my_fam}</option>
                </select>
              </div>

              <div className="flex flex-col">
                <label htmlFor="need" className="block font-semibold mb-2 dark:text-white">{translation.need}</label>
                <select
                  id="need"
                  name="need"
                  value={formData.need}
                  onChange={handlePostDataChange}
                  className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                  required
                >
                  <option value="">{translation.need_details.select_need}</option>
                  <option value="zakat">{translation.need_details.zakat}</option>
                  <option value="donation">{translation.need_details.donation}</option>
                  <option value="help">{translation.need_details.help}</option>
                </select>
              </div>

              <div className="flex flex-col">
                <label htmlFor="needed_money" className="block font-semibold mb-2 dark:text-white">
                  {translation.money_need}
                </label>
                <input
                  type="number"
                  id="needed_money"
                  name="needed_money"
                  value={formData.needed_money}
                  placeholder={translation.money_need_ph}
                  onChange={handlePostDataChange}
                  onKeyDown={(e) => {
                    if (!/[0-9]/.test(e.key) && 
                        !["Backspace", "Delete", "Tab", "Enter", "ArrowLeft", "ArrowRight"].includes(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  min="0"
                  step="1"
                  inputMode="numeric"
                  className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                  required
                />
              </div>

              <div className="flex flex-col">
                  <label htmlFor="description" className="block font-semibold mb-2 dark:text-white">{translation.seeker_situation}</label>
                  <textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    placeholder={translation.seeker_situation_ph}
                    onChange={handlePostDataChange}
                    className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                    required
                  />
              </div>

            </div>

            <div className="text-center">
              {isLoading ? (
                <div className="inline-block loading-icon">
                  <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 dark:border-stone-100" />
                </div>
              ) : (

                <button
                  onClick={SubmitPostData}
                  className={`w-full rounded-md bg-blue-500 text-white font-extrabold px-4 py-2 hover:bg-blue-600 ${seekerVideoInProcess || placeVideoInProcess || docInProcess ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={seekerVideoInProcess || placeVideoInProcess || docInProcess}
                >
                  {translation.next}
                </button>
              )}

              {postDataErrorMessage && <div style={{ color: 'red', fontWeight: 'bold' }}>{postDataErrorMessage}</div>}

              {/* {(seekerVideoInProcess || placeVideoInProcess || docInProcess) && (
                <div style={{ color: 'red', fontWeight: 'bold', marginTop: '10px' }}>
                  Please wait until the file is uploaded
                </div>
              )} */}
            </div>

          </>
        ) : currentState === 2 ?  (
          <>
            <h2 className="mb-6 text-2xl dark:text-white">{translation.bank_details.heading2}</h2>

            <div className="pt-2 pb-5 space-y-4">
              {/* Bank Name */}
              {showBankDetailFields && (
                <div className="flex flex-col space-y-2">
                  <label htmlFor="bank_name" className="block font-semibold mb-2 dark:text-white">
                  {translation.bank_details.bank_name}
                  </label>
                  <input
                    type="text"
                    id="bank_name"
                    name="bank_name"
                    placeholder={translation.bank_details.bank_ph}
                    value={bankFormData.bank_name}
                    required
                    onChange={handleBankDataChange}
                    className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                  />
                </div>
              )}

              {/* Account Holder */}
              {showBankDetailFields && (
                <div className="flex flex-col space-y-2">
                  <label htmlFor="account_holder" className="block font-semibold mb-2 dark:text-white">
                  {translation.bank_details.account_holder_name}
                  </label>
                  <input
                    type="text"
                    id="account_holder"
                    required
                    name="account_holder"
                    placeholder={translation.bank_details.account_holder_name_ph}
                    value={bankFormData.account_holder}
                    onChange={handleBankDataChange}
                    className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                  />
                </div>
              )}

              {/* Account Number */}
              {showBankDetailFields && (
                <div className="flex flex-col space-y-2">
                  <label htmlFor="account_number" className="block font-semibold mb-2 dark:text-white">
                  {translation.bank_details.account_number}
                  </label>
                  <input
                    type="number"
                    id="account_number"
                    name="account_number"
                    onKeyDown={(e) => {
                      if (!/[0-9]/.test(e.key) && 
                          !["Backspace", "Delete", "Tab", "Enter", "ArrowLeft", "ArrowRight"].includes(e.key)) {
                        e.preventDefault();
                      }
                    }}
                    min="0"
                    step="1"
                    inputMode="numeric"
                    placeholder={translation.bank_details.account_number_ph}
                    required
                    value={bankFormData.account_number}
                    onChange={handleBankDataChange}
                    className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                  />
                </div>
              )}

              <hr />

              {/* No Bank Account Radio Button */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="no_bank_account" className="block font-semibold mb-2 dark:text-white">
                  <input
                    type="checkbox"
                    id="no_bank_account"
                    name="no_bank_account"
                    onChange={(e) => {setShowBankDetailFields(!e.target.checked)}}
                    className="mr-2 leading-tight"
                  />
                  <span className="text-sm">{translation.bank_details.no_bank_account}</span>
                </label>
              </div>
            </div>
            
            {/* <CustomButton 
              label='Previous'
              className="mb-2 bg-black hover:bg-gray-800"
              onClick={() => setCurrentStep(1)}
            /> */}


          <div className="text-center">
            {isLoading ? (
              <div className="inline-block loading-icon">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 dark:border-stone-100" />
              </div>
            ) : (

              <button
                onClick={SubmitPostBankData}
                className={`w-full rounded-md bg-blue-500 text-white font-extrabold px-4 py-2 hover:bg-blue-600 ${seekerVideoInProcess || placeVideoInProcess || docInProcess ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={seekerVideoInProcess || placeVideoInProcess || docInProcess}
              >
                {translation.next}
              </button>
            )}

            {postDataErrorMessage && <div style={{ color: 'red', fontWeight: 'bold' }}>{postDataErrorMessage}</div>}

            {/* {(seekerVideoInProcess || placeVideoInProcess || docInProcess) && (
              <div style={{ color: 'red', fontWeight: 'bold', marginTop: '10px' }}>
                Please wait until the file is uploaded
              </div>
            )} */}
          </div>

          </>
        ) : currentState ===3 ?  (
          <>
            <h2 className="mb-6 text-2xl dark:text-white">{translation.other.heading3}</h2>

            <div className="pt-2 pb-5 space-y-4">
              <div className="flex flex-col space-y-2">
                <label htmlFor="seeker_video" className="block font-semibold mb-2 dark:text-white">{translation.other.seeker_video}</label>
                <input
                  type="file"
                  id="seeker_video"
                  name="seeker_video"
                  required
                  disabled={seekerVideoInProcess || placeVideoInProcess || docInProcess}
                  onChange={handleSeekerFileChange}
                  className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                />
                {seekerVideoMessage && <div>{seekerVideoMessage}</div>}
              </div>
            </div>
            
            {/* <CustomButton 
              label='Previous'
              className="mb-2 bg-black hover:bg-gray-800"
              onClick={() => setCurrentStep(2)}
            /> */}

          <div className="text-center">
            {isLoading ? (
              <div className="inline-block loading-icon">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 dark:border-stone-100" />
              </div>
            ) : (

              <button
                onClick={handleSeekerFileChange}
                className={`w-full rounded-md bg-blue-500 text-white font-extrabold px-4 py-2 hover:bg-blue-600${seekerVideoInProcess || placeVideoInProcess || docInProcess ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={seekerVideoInProcess || placeVideoInProcess || docInProcess}
              >
                {translation.next}
              </button>
            )}

            {postDataErrorMessage && <div style={{ color: 'red', fontWeight: 'bold' }}>{postDataErrorMessage}</div>}

            {(seekerVideoInProcess || placeVideoInProcess || docInProcess) && (
              <div style={{ color: 'red', fontWeight: 'bold', marginTop: '10px' }}>
                {translation.upload_file_message}
              </div>
            )}
          </div>


          </>
        )  : currentState === 4 ? (
          <>
            <h2 className="mb-6 text-2xl dark:text-white">{translation.other.heading4}</h2>
            
            <div className="pt-2 pb-5 space-y-4">
              <div className="flex flex-col space-y-2">
                <label htmlFor="place_video" className="block font-semibold mb-2 dark:text-white">{translation.other.home_video}</label>
                <input
                  type="file"
                  id="place_video"
                  required
                  disabled={seekerVideoInProcess || placeVideoInProcess || docInProcess}
                  name="place_video"
                  onChange={handlePlaceFileChange}
                  className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                />
                {placeVideoMessage && <div>{placeVideoMessage}</div>}
              </div>
            </div>

            {/* <CustomButton 
                label='Previous'
                className="mb-2 bg-black hover:bg-gray-800"
                onClick={() => setCurrentStep(3)}
              /> */}

            <div className="text-center">
              {isLoading ? (
                <div className="inline-block loading-icon">
                  <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 dark:border-stone-100" />
                </div>
              ) : (
                <button
                  type="submit"
                  onClick={handlePlaceFileChange}
                  className={`w-full rounded-md bg-blue-500 text-white font-extrabold px-4 py-2 hover:bg-blue-600 ${seekerVideoInProcess || placeVideoInProcess || docInProcess ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={seekerVideoInProcess || placeVideoInProcess || docInProcess}
                >
                  {translation.next}
                </button>
              )}

              {postDataErrorMessage && <div style={{ color: 'red', fontWeight: 'bold' }}>{postDataErrorMessage}</div>}

              {(seekerVideoInProcess || placeVideoInProcess || docInProcess) && (
                <div style={{ color: 'red', fontWeight: 'bold', marginTop: '10px' }}>
                  {translation.upload_file_message}
                </div>
              )}
            </div>

          </>
        ) : currentState === 5 ? (
          <>
            <h2 className="mb-6 text-2xl dark:text-white">{translation.other.heading5}</h2>
            <form onSubmit={handleFileSubmit} className="pt-3 pb-6 space-y-4">
              {/* Electric Bill Upload */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="electric_bill" className="block font-semibold mb-2 dark:text-white">
                  {translation.other.elect_bill_name}
                </label>
                <input
                  type="file"
                  id="electric_bill"
                  required={!electricBillFile}
                  name="electric_bill"
                  disabled={docInProcess}
                  onChange={handleElectricBillSelection}
                  className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                />
                {electricBillMessage && (
                  <div className="text-sm mt-1">{electricBillMessage}</div>
                )}
                
                {/* Electric Bill Preview */}
                {electricBillPreview && (
                  <div className="mt-2 p-3 border rounded bg-gray-50 dark:bg-gray-800">
                    <div className="flex justify-between items-center mb-2">
                      <h4 className="font-medium text-sm">Preview:</h4>
                      <button 
                        type="button" 
                        onClick={cancelElectricBill}
                        className="text-red-500 hover:text-red-700 text-sm"
                      >
                        Cancel
                      </button>
                    </div>
                    {typeof electricBillPreview === 'string' && !electricBillPreview.startsWith('data:image') ? (
                      <div className="flex items-center space-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                        <span className="text-sm text-gray-700 dark:text-gray-300">{electricBillFile?.name}</span>
                      </div>
                    ) : (
                      <img src={electricBillPreview} alt="Electric Bill Preview" className="max-h-48 rounded" />
                    )}
                  </div>
                )}
              </div>
              
              {/* CNIC Upload */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="seeker_cnic" className="block font-semibold mb-2 dark:text-white">
                  {translation.other.seeker_cnic}
                </label>
                <input
                  type="file"
                  id="seeker_cnic"
                  required={!cnicFile}
                  name="seeker_cnic"
                  disabled={docInProcess}
                  onChange={handleCnicSelection}
                  className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
                />
                {cnicMessage && (
                  <div className="text-sm mt-1">{cnicMessage}</div>
                )}
                
                {/* CNIC Preview */}
                {cnicPreview && (
                  <div className="mt-2 p-3 border rounded bg-gray-50 dark:bg-gray-800">
                    <div className="flex justify-between items-center mb-2">
                      <h4 className="font-medium text-sm">Preview:</h4>
                      <button 
                        type="button" 
                        onClick={cancelCnic}
                        className="text-red-500 hover:text-red-700 text-sm"
                      >
                        Cancel
                      </button>
                    </div>
                    {typeof cnicPreview === 'string' && !cnicPreview.startsWith('data:image') ? (
                      <div className="flex items-center space-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                        <span className="text-sm text-gray-700 dark:text-gray-300">{cnicFile?.name}</span>
                      </div>
                    ) : (
                      <img src={cnicPreview} alt="CNIC Preview" className="max-h-48 rounded" />
                    )}
                  </div>
                )}
              </div>
              
              {/* Submit Button */}
              <div className="text-center">
                {isLoading ? (
                  <div className="inline-block loading-icon">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 dark:border-stone-100" />
                  </div>
                ) : (
                  <button
                    type="submit"
                    className={`w-full rounded-md bg-blue-500 text-white font-extrabold px-4 py-2 hover:bg-blue-600 ${
                      docInProcess ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                    disabled={docInProcess || !electricBillFile || !cnicFile}
                  >
                    {translation.submit}
                  </button>
                )}
                
                {postDataErrorMessage && (
                  <div className="text-red-500 font-bold mt-2">{postDataErrorMessage}</div>
                )}
                
                {docInProcess && (
                  <div className="text-red-500 font-bold mt-2">
                    {translation.upload_file_message}
                  </div>
                )}
              </div>
            </form>
          </>
        ): (
          <>
            <div className="space-y-2">

              <div className="group relative overflow-hidden rounded-xl bg-teal-50 p-6 shadow-lg transition-all hover:shadow-xl dark:bg-teal-900/30">
                <div className="absolute -right-6 -top-6 text-9xl text-teal-100 opacity-50 transition-opacity group-hover:opacity-70 dark:text-teal-800">
                  <i className="fas fa-user-edit"></i>
                </div>
                <div className="relative">
                  <div className="mb-4 flex items-center gap-3">
                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-teal-600 text-white">
                      <i className="fas fa-user text-lg"></i>
                    </div>
                    <h3 className="text-xl font-semibold text-gray-800 dark:text-white/90">
                      {translation.submit_message}
                    </h3>
                  </div>
                </div>
              </div>
                

              <button
                  type="submit"
                  onClick={viewPost}
                  className={`w-full rounded-md bg-blue-500 text-white font-extrabold px-4 py-2 hover:bg-blue-600 ${seekerVideoInProcess || placeVideoInProcess || docInProcess ? 'opacity-50 cursor-not-allowed' : ''}`}
                  disabled={seekerVideoInProcess || placeVideoInProcess || docInProcess}
                >
                  view post
              </button>

            </div>
          </>
        )
      }
    </>
  )

  return (
    <>
      <Modal 
        isOpen={useCreatePostModal.isOpen}
        close={useCreatePostModal.close}
        label={translation.modal_name}
        content={content}
      />
    </>
  )
}

export default CreatePostModal