
import Modal from "./Modal";
import CustomButton from "./CustomButton";
import React, { useState } from 'react';
import useSupportSeekerModal from "../hooks/useSupportSeekerModal";
import { useDonateMutation } from "../../services/postApis";

import { useGetPostBankDetailQuery } from "../../services/postApis";
import { useTranslation } from "react-i18next";



const SupportSeekerModal = ({ slug, isDonor }) => {

  const [donateMutation] = useDonateMutation();
  const [selectedOption, setSelectedOption] = useState(0);
  const SupportSeekerModal = useSupportSeekerModal();
  const {data: bankData, isLoading } = useGetPostBankDetailQuery(slug, {skip: !SupportSeekerModal.isOpen || !isDonor})
  const {t} = useTranslation('post')
  const translation = t('post-support')

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  
  const handleSubmitDonation = async () => {
    const formData = new FormData();
    formData.append("slug", slug);
    formData.append('amount', selectedOption);

    try {
      await donateMutation(formData); // Await the API call
      setSelectedOption(0); // Reset the amount field
      SupportSeekerModal.close();

      setTimeout(() => {
      }, 5000); // 10,000 milliseconds = 10 seconds

    } catch (error) {
      console.error("Error:", error);
    }
  }

  const content = (
    <>
      
      <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mb-4">
        <div className="text-slate-950 dark:text-stone-200">
          <p className="text-md font-bold">{translation.first_name}</p>
          <p>
          {translation.first_desc}
          </p>
        </div>
        <div className="text-slate-950 dark:text-stone-200">
          <p className="text-md font-bold">{translation.second_name}</p>
          <p>
            {translation.second_desc}
          </p>
        </div>
        <div className="text-slate-950 dark:text-stone-200">
          <p className="text-md font-bold">{translation.third_name}</p>
          <p>
            {translation.third_desc}
          </p>
        </div>
        <hr/>

        <div className="text-slate-950 dark:text-stone-200">
          <p className="text-xl font-bold">{translation.seeker_bank}</p>

          {!isDonor ? (
            <p className="text-sm text-gray-600 dark:text-gray-400">
              {translation.only_donor}
            </p>
          ) : isLoading ? (
            <p className="mt-2 text-gray-500 dark:text-gray-400">Loading bank details...</p>
          ) : bankData ? (
            <div className="mt-2 ml-4">
              <p>
                <b>Account Holder:</b> {bankData.account_holder}
              </p>
              <p>
                <b>Bank Name:</b> {bankData.bank_name}
              </p>
              <p>
                <b>Account Number:</b> {bankData.account_number}
              </p>
            </div>
          ) : (
            <p className="mt-2 text-gray-500 dark:text-gray-400">
              {translation.seeker_no_bank}
            </p>
          )}
        </div>
        
      </div>
      
      <form onSubmit={(e) => handleSubmitDonation()} className="space-y-4">
        <div className="flex flex-col text-slate-950 dark:text-stone-200">
          
          <div className="space-y-2">
            <div className="">
              {translation.amount_message}
            </div>
            <div>
            <input
              type="number"
              value={selectedOption}
              onChange={handleOptionChange}
              onKeyDown={(e) => {
                if (!/[0-9]/.test(e.key) && 
                    !["Backspace", "Delete", "Tab", "Enter", "ArrowLeft", "ArrowRight"].includes(e.key)) {
                  e.preventDefault();
                }
              }}
              min="0"
              step="1"
              inputMode="numeric"
              className="text-dark-1 dark:text-white dark:bg-black border border-gray-300 rounded p-2 w-full"
            />
            </div>
          </div>
{/* 
          <div className="space-y-2 mt-5">
            <div className="">
              Kindly upload the payment slip
            </div>
            <div>
            <input
              type="file"
              value={selectedOption}
              onChange={handleOptionChange}
              className="text-dark-1 dark:text-white dark:bg-black border border-gray-300 rounded p-2 w-full"
            />
            </div>
          </div> */}

        </div>
        <div className="flex justify-between">
          <CustomButton 
            label="Submit"  
            onClick={handleSubmitDonation}
            className={"bg-blue-500"}
          />
        </div>
      </form>

    </>
  )

  return (
    <Modal 
      isOpen={SupportSeekerModal.isOpen}
      close={SupportSeekerModal.close}
      label={translation.name}
      content={content}
    />
  )
}

export default SupportSeekerModal