import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRegisterUserMutation } from '../../services/userAuthApi';
import { storeToken } from '../../storage/LocalStorageService';
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Registration = () => {
  const [server_error, setServerError] = useState({});
  const navigate = useNavigate();
  const [gender, setGender] = useState('');
  const [registerUser, { isLoading }] = useRegisterUserMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const actualData = {
      email: data.get('email'),
      full_name: data.get('fullname'),
      gender: gender,
      password: data.get('password'),
      password2: data.get('password2'),
    };

    const res = await registerUser(actualData);

    if (res.error) {
      // Handle both field errors and non-field errors
      setServerError({
        ...res.error.data?.errors,         // Field-specific errors
        non_field_errors: res.error.data?.errors?.non_field_errors || []
      });
    }
    if (res.data) {
      storeToken(res.data.token);
      navigate('/auth/verify-email', { state: { email: actualData.email } });
    }
  };

  return (
    <form className="overflow-auto max-h-96 w-full RegisterCard" id="registration-form" onSubmit={handleSubmit}>
      {/* Full Name Input */}
      <div className="mb-4">
        <label htmlFor="fullname" className="block font-bold text-black dark:text-stone-50 mb-2">Full Name</label>
        <input type="text" id="fullname" name="fullname" required className="px-3 py-2 w-full rounded border focus:outline-none focus:border-blue-500 text-black" />
        {server_error.full_name && <p className="text-red-500 text-xs mt-1">{server_error.full_name[0]}</p>}
      </div>

      {/* Gender Selection */}
      <div className="mb-4">
        <label className="block font-bold text-black dark:text-stone-50 mb-2">Gender</label>
        <div className="flex">
          <button
            type="button"
            onClick={() => setGender('female')}
            className={`mr-2 px-4 py-2 rounded ${gender === 'female' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            Female
          </button>
          <button
            type="button"
            onClick={() => setGender('male')}
            className={`px-5 py-2 rounded ${gender === 'male' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
          >
            Male
          </button>
        </div>
        {server_error.gender && <p className="text-red-500 text-xs mt-1">{server_error.gender[0]}</p>}
      </div>

      {/* Email Input */}
      <div className="mb-4">
        <label htmlFor="email" className="block font-bold text-black dark:text-stone-50 mb-2">Email Address</label>
        <input type="email" id="email" name="email" required className="px-3 py-2 w-full rounded border focus:outline-none focus:border-blue-500 text-black" />
        {server_error.email && <p className="text-red-500 text-xs mt-1">{server_error.email[0]}</p>}
      </div>

      {/* Password Inputs */}
      <div className="mb-4">
        <label htmlFor="password" className="block font-bold text-black dark:text-stone-50 mb-2">Password</label>
        <input type="password" id="password" name="password" required className="px-3 py-2 w-full rounded border focus:outline-none focus:border-blue-500 text-black" />
        {server_error.password && <p className="text-red-500 text-xs mt-1">{server_error.password[0]}</p>}
      </div>

      <div className="mb-4">
        <label htmlFor="password2" className="block font-bold text-black dark:text-stone-50 mb-2">Confirm Password</label>
        <input type="password" id="password2" name="password2" required className="px-3 py-2 w-full rounded border focus:outline-none focus:border-blue-500 text-black" />
        {server_error.password2 && <p className="text-red-500 text-xs mt-1">{server_error.password2[0]}</p>}
      </div>

      {/* Submit Button */}
      <div className="text-center">
        {isLoading ? (
          <div className="inline-block loading-icon">
            <AiOutlineLoading3Quarters className="animate-spin" />
          </div>
        ) : (
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
          >
            Sign up
          </button>
        )}
      </div>

      {/* Non-field Errors */}
      {server_error.non_field_errors && (
        <div className="text-red-500 text-center text-base mt-2 font-bold text-black dark:text-white ">
          {server_error.non_field_errors.map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )}
    </form>
  );
};

export default Registration;