import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaFilter } from "react-icons/fa";

const FilterPost = ({isFilterActive, translation}) => {
  const [filterData, setFilterData] = useState({});
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);

  const handleFilter = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilterData({ ...filterData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const queryParams = Object.entries(filterData)
      .filter(([key, value]) => value !== '') 
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
    const url = queryParams ? `/filter?${queryParams}` : '/'; 
    navigate(url);
  };

  return (
    <div className="w-full min-w-[220px]">
      <div className="relative mb-3">
        <button 
          className={`flex w-full items-center hover:bg-slate-200 dark:hover:bg-slate-800 rounded-lg p-2 dark:text-white 
            ${isFilterActive ? "bg-slate-200 dark:bg-slate-800" : ""}`}
          onClick={handleFilter}
          aria-expanded={isOpen}
          aria-haspopup="true"
        >
          <FaFilter />
          <p className="px-5">{translation.filter_name}</p>
        </button>

        <div
          className={`${isOpen ? "block" : "hidden"} w-full rounded-md`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="account-menu"
        > 
          <form onSubmit={handleSubmit} className='grid grid-cols-1 gap-2 mb-3'>
            {/* <div className='flex flex-col ps-4'>
              <label htmlFor='support' className='text-sm w-full font-medium text-slate-950 block dark:text-white'>{translation.support}</label>
              <select id='support' name='support' onChange={handleChange} className='flex h-5 rounded-md color-slate-950 dark:bg-gray-600 dark:text-blue-50 px-1'>
                <option value=''>Select</option>
                <option value='family'>{translation.support_sub.need_family}</option>
                <option value='person'>{translation.support_sub.individuals}</option>
              </select>
            </div>
             */}
            <div className='flex flex-col ps-4'>
              <label htmlFor='need' className='text-sm w-full font-medium text-slate-950 block dark:text-white'>{translation.support_by}</label>
              <select id='need' name='need' onChange={handleChange} className='flex h-5 rounded-md color-slate-950 dark:bg-gray-600 dark:text-blue-50 px-1'>
                <option value=''>Select</option>
                <option value='zakat'>{translation.support_by_sub.zakat}</option>
                <option value='donation'>{translation.support_by_sub.donation}</option>
                <option value='help'>{translation.support_by_sub.help}</option>
              </select>
            </div>

            <div className='flex flex-col ps-4'>
              <label htmlFor='need_amount_min' className='text-sm w-full font-medium text-slate-950 block dark:text-white'>{translation.how_much}</label>
              <input type='number' id='need_amount_min' name='need_amount_min' className='flex h-5 rounded-md color-slate-950 bg-slate-200 dark:bg-gray-600 dark:text-blue-50 px-1' onChange={handleChange} placeholder='0'/>
            </div>

            <div className='flex flex-col ps-4'>
              <label htmlFor='address' className='text-sm w-full font-medium text-slate-950 block dark:text-white'>{translation.seeker_add}</label>
              <input type='text' id='address' name='address' className='flex h-5 rounded-md color-slate-950 bg-slate-200 dark:bg-gray-600 dark:text-blue-50 px-1' onChange={handleChange} placeholder='karachi'/>
            </div>

            <div className='flex flex-col ps-4'>
              <label htmlFor='bank_name' className='text-sm w-full font-medium text-slate-950 block dark:text-white'>{translation.seeker_bank}</label>
              <input type='text' id='bank_name' name='bank_name' className='flex h-5 rounded-md color-slate-950 bg-slate-200 dark:bg-gray-600 dark:text-blue-50 px-1' onChange={handleChange} placeholder='Bank Name' />

              <button type='submit' className='self-center bg-blue-500 text-white py-1 px-4 rounded px-1 text-sm w-full mt-3'>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FilterPost;