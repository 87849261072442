import Modal from "./Modal";
import usePostUpdateModalHook from "../hooks/useUpdatePostModal";
import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";

const UpdatePostModal = ({ slug, translation }) => {
  const useHook = usePostUpdateModalHook();
  const [postDataErrorMessage, setPostDataErrorMessage] = useState("");
  const [postSuccessMessage, setPostSuccessMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    post_type: "",
    need: "",
    seeker: "",
    description: "",
    address: "",
    phone_number: "",
    seeker_cnic: "",
    needed_money: 0,
    verified: 0,
  });

  const [originalFormData, setOriginalFormData] = useState({
    post_type: "",
    need: "",
    seeker: "",
    description: "",
    address: "",
    phone_number: "",
    seeker_cnic: "",
    needed_money: 0,
    verified: 0,
  });

  // Memoized function to prevent unnecessary re-creation
  const getPostData = useCallback(async () => {
    if (!slug || !useHook.isOpen) return;

    setIsLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/post/update-post/${slug}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      if (response.status === 200 && response.data) {
        setPostDataErrorMessage("");
        setFormData(response.data);
        setOriginalFormData(response.data);
      }
    } catch (error) {
      setPostDataErrorMessage(error.response?.data?.error || "An error occurred");
    } finally {
      setIsLoading(false);
    }
  }, [slug, useHook.isOpen]);

  // Fetch post data when `slug` or `useHook.isOpen` changes
  useEffect(() => {
    getPostData();
  }, [getPostData]);
  
  const handlePostDataChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: val,
    }));
  };


  const SubmitPostData = async (e) => {
    e.preventDefault();
    setIsLoading(true)

    const updatedFields = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== originalFormData[key]){
        acc[key] = formData[key];
      }
      return acc
    }, {});

    if (Object.keys(updatedFields).length === 0) {
      setPostDataErrorMessage(`${translation.no_changes_detected}`);
      setIsLoading(false);
      return;
    }

    const requiredFields = ['seeker', 'address', 'phone_number', 'seeker_cnic', 'post_type', 'need', 'description'];
    for (const field of requiredFields) {
      if (!formData[field] || (typeof formData[field] === 'string' && formData[field].trim() === '')) {
        setPostDataErrorMessage(`${translation[field + '_label'] || field} ${translation.field_cannot_be_empty}`);
        setIsLoading(false);
        return;
      }
    }

  
    // Create post
    const config = {
      headers: {
        'Content-Type': 'application/json', // Use JSON content type
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      }
    };

    try {
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_DOMAIN}/post/update-post/${slug}/`, JSON.stringify(updatedFields), config);
      setIsLoading(false)

      if (response.status === 200) {
        setPostDataErrorMessage("")
        setPostSuccessMessage(translation.updated_success)
        setTimeout(()=>{
          useHook.close()
          setPostSuccessMessage("")
        }, 2000)
      }
    } catch (error) {

      setIsLoading(false);
      if (error.response && error.response.data) {
        const errorData = error.response.data;
        if (errorData?.non_field_errors && Array.isArray(errorData?.non_field_errors)){
          setPostDataErrorMessage(errorData.non_field_errors[0]); 
        }
        else if (errorData?.detail) {
          setPostDataErrorMessage(errorData?.detail); 
        } 

        else if (errorData?.error && Array.isArray(errorData.error.error)) {
          setPostDataErrorMessage(errorData.error.error[0]); 
        } 
        else if (errorData?.error.seeker){
          setPostDataErrorMessage(`${translation.seeker_name}` + errorData.error.seeker[0]); 
        } 
        else if (errorData?.error.address){
          setPostDataErrorMessage(`${translation.address}` + errorData.error.address[0]); 
        } 
        else if (errorData?.error.phone_number){
          setPostDataErrorMessage(`${translation.seeker_phone}`  + errorData.error.phone_number[0]); 
        } 
        else if (errorData?.error.seeker_cnic){
          setPostDataErrorMessage(`${translation.seeker_cnic}` + errorData.error.seeker_cnic[0]); 
        } 
        else if (errorData?.error.needed_money){
          setPostDataErrorMessage(`${translation.money_need}` + errorData.error.needed_money[0]); 
        } 
        else if (errorData?.error.post_type){
          setPostDataErrorMessage(`${translation.asking_for}` + errorData.error.post_type[0]); 
        } 
        else if (errorData?.error.need){
          setPostDataErrorMessage(`${translation.need}` + errorData.error.need[0]); 
        } 
        else if (errorData?.error.description){
          setPostDataErrorMessage(`${translation.seeker_situation}` + errorData.error.description[0]); 
        } 
        else if (typeof errorData?.error === "string") {
          setPostDataErrorMessage(errorData.error);
        } else {
          setPostDataErrorMessage(`${translation.unknow_error}`);
        }
      }else {
        setPostDataErrorMessage(`${translation.unexpected_error}`);
      };
    };
  };




  const content = (
    <>
      <h2 className="mb-6 text-2xl dark:text-white">{translation.heading}</h2>
      {postSuccessMessage && <p className="p-2 font-base font-bold text-white bg-green-500 hover:cursor-pointer rounded-lg">{postSuccessMessage}</p>}

      <div className="pt-2 pb-5 space-y-5 ">
        <div className="flex flex-col">
          <label htmlFor="seeker" className="block font-semibold mb-2 dark:text-white">{translation.seeker_name}</label>
          <input
            type="text"
            id="seeker"
            name="seeker"
            placeholder={translation.seeker_name_ph}
            value={formData.seeker}
            onChange={handlePostDataChange}
            className="text-black block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
            required
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="address" className="block font-semibold mb-2 dark:text-white">{translation.address}</label>
          <input
            type="text"
            id="address"
            name="address"
            placeholder={translation.address_ph}
            value={formData.address}
            onChange={handlePostDataChange}
            className="text-black block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
            required
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="phone_number" className="block font-semibold mb-2 dark:text-white">{translation.seeker_phone}</label>
          <input
            type="tel"
            id="phone_number"
            name="phone_number"
            placeholder={translation.seeker_phone_ph}
            value={formData.phone_number}
            onKeyDown={(e) => {
              if (!/[0-9]/.test(e.key) && 
                  !["Backspace", "Delete", "Tab", "Enter", "ArrowLeft", "ArrowRight"].includes(e.key)) {
                e.preventDefault();
              }
            }}
            min="0"
            step="1"
            inputMode="numeric"
            onChange={handlePostDataChange}
            className="text-black block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
            required
            maxLength={11}
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="seeker_cnic" className="block font-semibold mb-2 dark:text-white">{translation.seeker_cnic}</label>
          <input
            type="tel"
            id="seeker_cnic"
            name="seeker_cnic"
            placeholder={translation.seeker_cnic_ph}
            value={formData.seeker_cnic}
            onKeyDown={(e) => {
              if (!/[0-9]/.test(e.key) && 
                  !["Backspace", "Delete", "Tab", "Enter", "ArrowLeft", "ArrowRight"].includes(e.key)) {
                e.preventDefault();
              }
            }}
            min="0"
            step="1"
            inputMode="numeric"
            onChange={handlePostDataChange}
            className="text-black block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
            required
            maxLength={20}
          />
        </div>


        <div className="flex flex-col">
          <label htmlFor="post_type" className="block font-semibold mb-2 dark:text-white">{translation.asking_for}</label>
          <select
            id="post_type"
            name="post_type"
            value={formData.post_type}
            onChange={handlePostDataChange}
            className="text-black block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
            required
          >
            <option value="">{translation.purpose_detail.select_purpose}</option>
            <option value="family">{translation.purpose_detail.asking_for_other_fam}</option>
            <option value="person">{translation.purpose_detail.asking_for_my_fam}</option>
          </select>
        </div>

        <div className="flex flex-col">
          <label htmlFor="need" className="block font-semibold mb-2 dark:text-white">{translation.need}</label>
          <select
            id="need"
            name="need"
            value={formData.need}
            onChange={handlePostDataChange}
            className="text-black block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
            required
          >
            <option value="">{translation.need_details.select_need}</option>
            <option value="zakat">{translation.need_details.zakat}</option>
            <option value="donation">{translation.need_details.donation}</option>
            <option value="help">{translation.need_details.help}</option>
          </select>
        </div>

        <div className="flex flex-col">
        <label htmlFor="needed_money" className="block font-semibold mb-2 dark:text-white">{translation.money_need}</label>
          <input
            type="number"
            id="needed_money"
            name="needed_money"
            placeholder={translation.money_need_ph}
            value={formData.needed_money}
            onKeyDown={(e) => {
              if (!/[0-9]/.test(e.key) && 
                  !["Backspace", "Delete", "Tab", "Enter", "ArrowLeft", "ArrowRight"].includes(e.key)) {
                e.preventDefault();
              }
            }}
            min="0"
            step="1"
            inputMode="numeric"
            onChange={handlePostDataChange}
            className="text-black block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
            required
          />
        </div>

        <div className="flex flex-col">
            <label htmlFor="description" className="block font-semibold mb-2 dark:text-white">{translation.seeker_situation}</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handlePostDataChange}
              placeholder={translation.seeker_situation_ph}
              className="text-black block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
              required
            />
        </div>

      </div>

      <div className="text-center">
        {isLoading ? (
          <div className="inline-block loading-icon">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 dark:border-stone-100" />
          </div>
        ) : (

          <button
            onClick={SubmitPostData}
            className="w-full rounded-md bg-blue-500 text-white font-extrabold px-4 py-2 hover:bg-blue-600"
          >
            {translation.submit}
          </button>
        )}

        {postDataErrorMessage && <div style={{ color: 'red', fontWeight: 'bold' }}>{postDataErrorMessage}</div>}

      </div>
    </>
  )

  return (
    <>
      <Modal 
        isOpen={useHook.isOpen}
        close={useHook.close}
        label={translation.modal_name}
        content={content}
      />
    </>
  )

}


export default UpdatePostModal;