import React from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import PostCard from './post-card';
import MessagePopUp from './MessagePopUp';
import {Loading} from "../../components/Loading";
import { useTranslation } from 'react-i18next';


const PostList = ({ endpoint, queryParams = "" }) => {

  const [myErrors, setMyErrors] = React.useState('');
  const {t} = useTranslation("post")
  const translation = t('post-list')
  
  const fetchPosts = async ({ pageParam = 1 }) => {
    
    try {
      const token = localStorage.getItem('access_token');
      const headers = token ? { Authorization: `Bearer ${token}` } : {};

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_DOMAIN}${endpoint}?page=${pageParam}${queryParams ? `&${queryParams}` : ''}`,
        { headers }
      );

      if (!response.ok) {
        setMyErrors(`${translation.no_resp}`);
      }

      return await response.json();
    } catch (error) {
      setMyErrors(`${translation.server_error}`);
    }
  };

  const {
    data,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ['posts', endpoint, queryParams],
    queryFn: ({ pageParam = 1 }) => fetchPosts({ pageParam }),
    getNextPageParam: (lastPage) => {
      return lastPage?.next ? lastPage.next : undefined;
    },
  }); 

  return (
    <div className="mt-16 ">
      {isLoading ? ( 
        <Loading />
      ) : ( 
          <>
            <div className="grid grid-cols-1 gap-5 max-w-2xl">
              {data?.pages?.some((page) => page?.results?.length) ? (
                data.pages.map((page) =>
                  page.results.map((post) => (
                    <PostCard key={post.id} post={post} />
                  ))
                )
              ) : (
                <MessagePopUp message={myErrors || `${translation.server_not_ready}`}/>
              )}
            </div>

            {hasNextPage && (
              <button
                className="max-w-2xl mt-6 mb-5 p-3 rounded-full bg-gray-500 text-white dark:bg-gray-600 hover:bg-gray-400 dark:text-white font-bold"
                onClick={() => fetchNextPage()}
                disabled={isFetchingNextPage}
              >
                {isFetchingNextPage ? `${translation.loading}` : `${translation.load_more}`}
              </button>
            )}
          </>
        )
      }
      
    </div>
  );
};

export default PostList;