import Modal from "./Modal";
import React from "react";
import useDonorsModal from "../hooks/useDonorsModal";
import { useGetPostDonorListQuery } from "../../services/postApis";
import { useTranslation } from "react-i18next";

const DonorsModal = ({ slug }) => {

  const donorsModal = useDonorsModal();
  const { data: donorList, isLoading} = useGetPostDonorListQuery(slug, {skip: !donorsModal.isOpen,});
  const {t} = useTranslation("post")
  const translation = t('post-donors')

  const content = (
    <div className="grid grid-cols-1 md:grid-cols-1 gap-8 mb-4">
      
      {isLoading && (
        <p className="text-slate-950 dark:text-stone-200">{translation.loading_donors}</p>
      )}

      {donorList ? (
        <div className="text-slate-950 dark:text-stone-200">
          {donorList.map((object, index) => (
            <div className="flex flex-row justify-between items-start bg-slate-400 rounded-lg p-1">
              <p key={index} className="p-1 rounded-lg font-semibold text-black">{translation.donor}{object.donor}</p> |
              <p key={index} className="p-1  rounded-lg font-semibold text-black">{translation.amount}{object.amount}</p>
            </div>
          ))}
        </div>
      ) : (
        !isLoading && (
          <p className="text-slate-950 dark:text-stone-200">
            {translation.no_donor}
            
          </p>
        )
      )}
    </div>
  );

  return (
    <Modal
      isOpen={donorsModal.isOpen}
      close={donorsModal.close}
      label={translation.heading}
      content={content}
    />
  );
};

export default DonorsModal;
