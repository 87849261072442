import React from "react";
import date from 'date-and-time';
import { useLocation, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MyDonorCommunity = () => {
  const location = useLocation();
  const membershipData = location.state;
  const {t} = useTranslation("joinDonor")
  const translation = t('MyDonorDashboard')



  if (!membershipData) {
    return <Navigate to="/" replace />;
  }

  const { startDate, endDate, plan, isActive } = membershipData;


  const formattedStartDate = startDate ? date.format(new Date(startDate), 'DD MMM YYYY, hh:mm A') : 'N/A';
  const formattedEndDate = endDate ? date.format(new Date(endDate), 'DD MMM YYYY, hh:mm A') : 'N/A';

  return (
    <>
      <div className="p-4 max-w-md mx-auto mt-20">
        <div className="bg-white rounded-lg shadow-md p-6 dark:bg-gray-800">
          <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">
            {translation.detail}
          </h2>
          <div className="space-y-3">
            <div className="flex justify-between items-center border-b pb-2">
              <span className="font-medium text-gray-600 dark:text-gray-300">{translation.plan}</span>
              <span className="text-gray-800 dark:text-white">{plan}</span>
            </div>
            <div className="flex justify-between items-center border-b pb-2">
              <span className="font-medium text-gray-600 dark:text-gray-300">{translation.status}</span>
              <span className={`px-3 py-1 rounded-full ${
                isActive ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
              }`}>
                {isActive ? `${translation.active}` : `${translation.inactive}`}
              </span>
            </div>
            <div className="flex justify-between items-center border-b pb-2">
              <span className="font-medium text-gray-600 dark:text-gray-300">{translation.start}</span>
              <span className="text-gray-800 dark:text-white">{formattedStartDate}</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="font-medium text-gray-600 dark:text-gray-300">{translation.end}</span>
              <span className="text-gray-800 dark:text-white">{formattedEndDate}</span>
            </div>
          </div>
        </div>
      </div>


      <div className="m-10 max-w-md mx-auto bg-gradient-to-r  from-green-50 to-emerald-50 dark:from-gray-800 dark:to-gray-900 rounded-xl shadow-sm hover:shadow-lg transition-all duration-300 border border-green-100 dark:border-gray-700">
        <div className="p-5">
          <div className="flex items-start">
            <div className="flex flex-col items-center">
              <h3 className="font-semibold text-xl text-gray-800 dark:text-white">{translation.join_wc}</h3>
              <p className="mt-3 text-gray-600 dark:text-gray-300 text-base text-justify">{translation.join_wc_message}</p>
              
              <div className="mt-3 flex flex-col sm:flex-row sm:items-center gap-3">
                <button
                  onClick={() => window.open('https://whatsapp.com/channel/0029Vb4L7Cz0LKZK3dhU9l09', '_blank')}
                  className="flex items-center justify-center gap-2 px-4 py-2.5 bg-green-500 hover:bg-green-600 text-white font-medium rounded-lg transition-colors w-full sm:w-auto"
                >
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"></path>
                  </svg>
                  {translation.join_now}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};

export default MyDonorCommunity;