import React, { useEffect, useState } from 'react';
import { FaExclamationCircle, FaTimes } from 'react-icons/fa';

const MessagePopUp = ({ message, duration = 2500, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      if (onClose) onClose(); 
    }, duration);

    return () => clearTimeout(timer); 
  }, [duration, onClose]);

  if (!visible) return null;

  return (
    <div className="fixed top-10 left-0 right-0 z-50 flex items-center justify-center p-4">
      <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg max-w-md w-full flex items-center justify-between border-l-4 border-blue-500">
        <div className="flex items-center">
          <FaExclamationCircle className="text-blue-500 text-xl mr-3" />
          <p className="text-gray-600 dark:text-gray-300">{message}</p>
        </div>
        <button
          className="text-gray-400 hover:text-gray-600 dark:hover:text-gray-200 transition-colors duration-200"
          onClick={() => setVisible(false)}
        >
          <FaTimes className="text-xl" />
        </button>
      </div>
    </div>
  );
};

export default MessagePopUp;