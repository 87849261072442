import React from 'react';
import { useProfileQuery, useFollowOrUnfollowMutation } from '../../services/profileApis';
import { useGetLoggedUserQuery } from '../../services/userAuthApi';
import { useParams } from 'react-router-dom';
import PostCard from '../../post/components/post-card';
import { useProfilePostQuery } from '../../services/postApis';
import ReportProfileModal from '../modals/ReportModal';
import useUpdateProfileDetails from '../hooks/useUpdateProfileDetailsModal';
import UpdateProfieDetailsModal from '../modals/UpdateDetails';
import { useTranslation } from 'react-i18next';
import useReportProfile from '../hooks/useReportModal';
import {Loading} from "../../components/Loading"

const ProfileDetail = () => {
  const [follow, setFollow] = React.useState(false);
  const { username } = useParams();
  const updateProfileModalhook = useUpdateProfileDetails();
  const useReportModal = useReportProfile()
  const localUsername = JSON.parse(localStorage.getItem('user'));
  const {t} = useTranslation('profile')
  const translation = t('profile')

  const { data, isError, error, isLoading } = useProfileQuery(username);
  const [followOrUnfollow] = useFollowOrUnfollowMutation();
  const { data: userDetail, isError: isErrorUser, error: userError, isLoading: isLoadingUser } = useGetLoggedUserQuery(username);
  const { data: posts, isSuccess } = useProfilePostQuery();

  const handleFollowOrUnfollow = async () => {
    try {
      await followOrUnfollow(username).unwrap();
    } catch (error) {
      console.log(error);
    }
  };

  const handleFollowClick = () => {
    handleFollowOrUnfollow();
    setFollow(!follow);
  };

  if (isLoading || isLoadingUser) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  const isOwnProfile = localUsername?.username === username;
  
  if (isErrorUser) return <div className="text-red-500 text-center">Error: {userError.message}</div>;
  if (isError) return <div className="text-red-500 text-center">Error: {error.message}</div>;
  if (!userDetail) return <div className="text-red-500 text-center">Error: User details not found.</div>;

  return (
    <div className="container mx-auto mt-20 p-6">
      <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6">
        <div className="flex flex-col md:flex-row items-center md:items-start gap-6">
          <div className="relative ">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-36 dark:text-white">
              <path fillRule="evenodd" d="M18.685 19.097A9.723 9.723 0 0 0 21.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 0 0 3.065 7.097A9.716 9.716 0 0 0 12 21.75a9.716 9.716 0 0 0 6.685-2.653Zm-12.54-1.285A7.486 7.486 0 0 1 12 15a7.486 7.486 0 0 1 5.855 2.812A8.224 8.224 0 0 1 12 20.25a8.224 8.224 0 0 1-5.855-2.438ZM15.75 9a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z" clipRule="evenodd" />
            </svg>

          </div>

          <div className="flex-1 text-center md:text-left">
            <h1 className="text-3xl font-semibold text-gray-900 dark:text-white">{userDetail.full_name}</h1>
            <p className="text-gray-500 dark:text-gray-300">@{userDetail.username}</p>
            <p className="mt-2 text-gray-600 dark:text-gray-400">{data.slogan}</p>
            <p className="mt-4 text-gray-700 dark:text-gray-300">{data.intro}</p>

            <div className="mt-6 flex gap-4 justify-center md:justify-start">
              {!isOwnProfile && (
                <button
                  className="px-5 py-2 text-white bg-blue-500 hover:bg-blue-600 rounded-lg shadow-md transition"
                  onClick={handleFollowClick}
                >
                  {follow ? `${translation.unfollow}` : `${translation.follow}`}
                </button>
              )}

              {!isOwnProfile && (
                <button 
                  onClick={() => useReportModal.open()} 
                  className="px-5 py-2 bg-gray-700 hover:bg-gray-800 text-white rounded-lg shadow-md transition">
                  {translation.report}
                </button>
              )}


              {isOwnProfile && (
                <button
                  onClick={() => updateProfileModalhook.open()}
                  className="px-5 py-2 bg-gray-700 hover:bg-gray-800 text-white rounded-lg shadow-md transition"
                >
                  {translation.edit_profile}
                </button>
              )}

            </div>
          </div>
        </div>
      </div>

      <div className="mt-10">
        <h2 className="text-2xl font-semibold text-gray-900 dark:text-white mb-6">{translation.posts}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {isSuccess && posts?.length > 0 ? (
            posts.map((post) => <PostCard key={post.id} post={post} />)
          ) : (
            <p className="text-gray-500 dark:text-gray-300">{translation.no_posts}</p>
          )}
        </div>
      </div>

      {updateProfileModalhook.isOpen && <UpdateProfieDetailsModal username={username} />}
      {useReportModal.isOpen && <ReportProfileModal username={username} />}

    </div>
  );
};

export default ProfileDetail;
