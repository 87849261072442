import React from 'react';

export const Loading = () => (
  <div className="fixed inset-0 bg-white dark:bg-slate-900 z-50 flex items-center justify-center min-h-screen">
    <div className="text-center space-y-4">

      {/* <img src="%PUBLIC_URL%/az-logo.png" alt="AddaZakat logo" className="max-h-48 rounded" /> */}
      
      <div className="animate-pulse text-2xl font-bold text-slate-800 dark:text-slate-200">
        AddaZakat
      </div>
      
      <p className="text-slate-600 dark:text-slate-400 font-medium animate-pulse">
        Loading...
      </p>

      <div className="w-48 h-1.5 bg-slate-200 dark:bg-slate-700 rounded-full overflow-hidden mx-auto">
        <div className="w-1/3 h-full bg-blue-500 animate-progress"></div>
      </div>
    </div>

    <style jsx>{`
      @keyframes spin {
        to { transform: rotate(360deg); }
      }
      @keyframes progress {
        0% { transform: translateX(-100%); }
        100% { transform: translateX(300%); }
      }
      .animate-progress {
        animation: progress 1.5s ease-in-out infinite;
      }
    `}</style>
  </div>
);