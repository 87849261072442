import React, {useEffect} from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import { FaHome, FaUserCheck, FaSave } from "react-icons/fa";
import FilterPost from "./filterPost";
import { getToken } from '../../storage/LocalStorageService';
import useCreatePost from '../../post/hooks/useCreatePost';
import { useGetUserMembershipQuery } from '../../services/userAuthApi';
import { useNavigate } from 'react-router-dom';
import { GrAdd } from 'react-icons/gr';
// import SupportUs from "../../post/modals/SupportUsModal";
// import useSupportUsModal from "../../post/hooks/useSupportUs";
import { useTranslation } from "react-i18next";

const Sidebar = ({ isSidebarOpen }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // const uSupportModal = useSupportUsModal()
  const {t} = useTranslation('common')
  const sidebar = t("sidebar")
  const navbar = t("navbar")
  const filter = t("filter")

  const isActive = (path) => {
    return location.pathname === path;
  };

  const { access_token } = getToken();
  const { open } = useCreatePost();
  const { data: membershipData, isLoading, isError } = useGetUserMembershipQuery();

  const handleCreatePostClick = () => {
    if (access_token) {
      open();
    } else {
      navigate('/auth/login');
    }
  };

  useEffect(() => {
    if (membershipData?.data?.is_active !== undefined) {
      localStorage.setItem('is_donor', membershipData.data.is_active);
    }
  }, [membershipData]);

  const handleJoinDonorCommunity = () => {
    if (access_token) {
      if (membershipData?.data?.is_active){
        const membershipState = {
          startDate: membershipData.data.starts,
          endDate: membershipData.data.ends,
          plan: membershipData.data.plan,
          isActive: membershipData.data.is_active,
          donorCount: membershipData.membership_count
        };
        navigate('/my-donor-community', {
          state: membershipState
        });
      } else {
        navigate('/join-donor-community');
      }
    } else {
      navigate('/auth/login');
    }
  };

  return (
    <div className={`bg-white h-full max-h-full fixed dark:bg-gray-700 overflow-y-auto top-0 sidebar ${isSidebarOpen ? "active w-full relative" : ""}`}>
      <div className="sidebar-content pt-12">
        <ul className="list-none mt-15 p-2 space-y-1">
          <li>
            <Link to="/">
              <div
                className={`flex items-center hover:bg-slate-200 dark:hover:bg-slate-800 rounded-lg p-2 dark:text-white ${
                  isActive("/") ? "bg-slate-200 dark:bg-slate-800" : ""
                }`}
              >
                <FaHome />
                <p className="px-5">{sidebar.home}</p>
              </div>
            </Link>
          </li>
          

          <li>
            <Link to="/saves">
              <div
                className={`flex items-center hover:bg-slate-200 dark:hover:bg-slate-800 rounded-lg p-2 dark:text-white ${
                  isActive("/saves") ? "bg-slate-200 dark:bg-slate-800" : ""
                }`}
              >
                <FaSave />
                <p className="px-5">{sidebar.saved_posts}</p>
              </div>
            </Link>
          </li>

          <li>
            <Link to="/history">
              <div
                className={`flex items-center hover:bg-slate-200 dark:hover:bg-slate-800 rounded-lg p-2 dark:text-white ${
                  isActive("/history") ? "bg-slate-200 dark:bg-slate-800" : ""
                }`}
              >
                <FaUserCheck />
                <p className="px-5">{sidebar.history}</p>
              </div>
            </Link>
          </li>

          {isLoading && isError ? (
            <li className="md:hidden flex items-center hover:bg-slate-200 dark:hover:bg-slate-800 rounded-lg p-2 dark:text-white 0 opacity-50 cursor-not-allowed">
              <p className="text-sm">Loading...</p>
            </li>
          ) : (
            <li
              onClick={handleJoinDonorCommunity}
              className={`md:hidden space-x-3 hover:cursor-pointer flex items-center hover:bg-slate-200 dark:hover:bg-slate-800 rounded-lg p-1 dark:text-white  transition-colors ${
                membershipData?.data?.is_active ? 'text-green-600' : 'text-black'
              }`}
            >   
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-5">
                  <path fillRule="evenodd" d="M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 0 1-.372-.568 6.787 6.787 0 0 1 1.019-4.38Z" clipRule="evenodd" />
                  <path d="M5.082 14.254a8.287 8.287 0 0 0-1.308 5.135 9.687 9.687 0 0 1-1.764-.44l-.115-.04a.563.563 0 0 1-.373-.487l-.01-.121a3.75 3.75 0 0 1 3.57-4.047ZM20.226 19.389a8.287 8.287 0 0 0-1.308-5.135 3.75 3.75 0 0 1 3.57 4.047l-.01.121a.563.563 0 0 1-.373.486l-.115.04c-.567.2-1.156.349-1.764.441Z" />
                </svg>

                <p className="hover:cursor-pointer flex items-center hover:bg-slate-200 dark:hover:bg-slate-800 rounded-lg p-1 dark:text-white  transition-colors ">  
                  {membershipData?.data?.is_active ? `${navbar.dc}`: `${navbar.jdc}`}
                </p>
            </li>
          )}

          <li
            onClick={handleCreatePostClick}
            className="md:hidden space-x-3 flex items-center hover:bg-slate-200 dark:hover:bg-slate-800 rounded-lg p-2 dark:text-white  hover:cursor-pointer transition-colors"
          >
            <GrAdd className="w-5 h-5" />
            <p className="text-sm">{navbar.create_post}</p>
          </li>

          {/* <li
            onClick={() => {uSupportModal.open()}}
            className="md:hidden space-x-3 flex items-center hover:bg-slate-200 dark:hover:bg-slate-800 rounded-lg p-2 dark:text-white  hover:cursor-pointer  transition-colors"
          > 
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z" />
            </svg>
            <p className="text-sm">{navbar.support_us}</p>
            <SupportUs />

          </li> */}

          <li>
            <FilterPost className="w-full" isFilterActive={isActive("/filter")} translation={filter} />
          </li>
         


        </ul>
      </div>
    </div>
  );
};

export default Sidebar;