import React from 'react';
//import { AiOutlineLike } from "react-icons/ai";
//import { BiDislike } from "react-icons/bi";
import { multiFormatDateString } from '../utils/DateConvertor';
import '../assets/css/comments.css';

const Comment = ({ comment }) => {
  if (!comment) return null;
  
  return (
    <div className='m-3 border border-gray-600 dark:border-white dark:bg-gray-500 bg-blue-50 rounded-md p-2 w-auto'>
      <div className='flex justify-between '>
        <span className='text-lg font-bold dark:text-black border-b-2 border-b-gray-800'>{comment.user}</span>
        <span className='mr-30 text-dark-1 dark:text-gray'>{multiFormatDateString(comment.created)}</span>     
      </div>
      <pre className='m-3 text-dark-1 rounded-lg  dark:text-black font-sans whitespace-pre-wrap break-words'>
        {comment.body}
      </pre>
      {/* <div className='flex items-center'>
        <button onClick={() => onUpvote(comment.id)} className='text-gray-600 mr-4'><AiOutlineLike /></button>
        <button onClick={() => onDownvote(comment.id)} className='text-gray-600 mr-4'><BiDislike /></button>
        <button onClick={() => onReply(comment.id)} className='text-gray-600'>Reply</button>
      </div> */}
    </div>
  )
}

export default Comment;
