import Modal from "./Modal";
import CustomButton from "./CustomButton";
import React, { useState } from 'react';
import useReportProfile from "../hooks/useReportModal";
import { useTranslation } from "react-i18next";
import { useReportMutation } from '../../services/profileApis';



const ReportProfileModal = ({ username }) => {
  const [reportMutation] = useReportMutation();
  const [selectedOption, setSelectedOption] = useState('');
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false); 
  const uReportModal = useReportProfile();
  const {t} = useTranslation('post')
  const translation = t('post-report')

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmitReport = async () => {

    const formData = new FormData();
    formData.append("username", username);
    formData.append('problem', selectedOption);

    try {
      const response = await reportMutation(formData);
      if (response.data) {
        setMessage(response.data.message); 
        setIsError(false); 
        setTimeout(() => {
          uReportModal.close();
          setMessage(''); 
        }, 3000);
      } else if (response.error) {
        setMessage(response.error.data.error); 
        setIsError(true); 
        setTimeout(() => {
          uReportModal.close();
          setMessage(''); 
        }, 3000);
      }
      setSelectedOption('');
    } catch (error) {
      setMessage("An unexpected error occurred. Please try again.");
      setIsError(true); 
      setTimeout(() => {
        uReportModal.close();
        setMessage(''); 
      }, 3000);
    }
  };

  const content = (
    <>
      {message && (
        <div className={`mb-4 p-4 rounded ${isError ? "bg-red-100 text-red-700" : "bg-green-100 text-green-700"}`}>
          {message}
        </div>
      )}
      <form>
        <div className="mb-4 text-slate-950 dark:text-stone-200">
        {translation.name}
          <div className="mt-2 grid grid-cols-1 gap-2">
            <label className="inline-flex items-center">
              <input
                type="radio"
                value={translation.fake_nature}
                checked={selectedOption === `${translation.fake_nature}`}
                onChange={handleOptionChange}
                className="form-radio text-blue-500 h-5 w-5"
              />
              <span className="px-3">{translation.fake_nature}</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                value={translation.already_posted}
                checked={selectedOption === `${translation.already_posted}`}
                onChange={handleOptionChange}
                className="form-radio text-blue-500 h-5 w-5"
              />
              <span className="px-3">{translation.already_posted}</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                value={translation.lying}
                checked={selectedOption === `${translation.lying}`}
                onChange={handleOptionChange}
                className="form-radio text-blue-500 h-5 w-5"
              />
              <span className="px-3">{translation.lying}</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                value={translation.inappropriate}
                checked={selectedOption === `${translation.inappropriate}`}
                onChange={handleOptionChange}
                className="form-radio text-yellow-500 h-5 w-5"
              />
              <span className="px-3">{translation.inappropriate}</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                value={translation.spam}
                checked={selectedOption === `${translation.spam}`}
                onChange={handleOptionChange}
                className="form-radio text-green-500 h-5 w-5"
              />
              <span className="px-3">{translation.spam}</span>
            </label>
          </div>
        </div>
        <div className="flex justify-between">
          <CustomButton
            label={translation.submit}
            onClick={handleSubmitReport}
            className={"bg-blue-500"}
          />
        </div>
      </form>
    </>
  );

  return (
    <Modal
      isOpen={uReportModal.isOpen}
      close={uReportModal.close}
      label={translation.name}
      content={content}
    />
  );
};

export default ReportProfileModal;