import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { CgProfile } from "react-icons/cg";
import LogoutButton from '../authPages/Logout_btn.js';
import Switcher from "./darkmode.js";
import { getUserInfo } from '../../storage/LocalStorageService';
import { getToken } from '../../storage/LocalStorageService';
import LanguageSelector from '../hooks/language-selector';

export default function AccountMenu({translation}) {
  const [isOpen, setIsOpen] = useState(false);
  const [userInfo] = useState(() => getUserInfo() || {});
  const { access_token } = getToken();
  
  const handleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative inline-block">
      <button
        className="relative z-10 p-2 rounded-md shadow-sm"
        onClick={handleMenu}
        aria-expanded={isOpen}
        aria-haspopup="true"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-9 h-9 text-black font-extrabold hover:bg-gray-200 rounded-full dark:text-white hover:cursor-pointer dark:bg-gray-600 dark:hover:bg-gray-800"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
          />
        </svg>
      </button>

      {access_token ? ( // Show menu for authenticated users
        <div
          className={`absolute rtl:left-0 ltr:right-0 origin-top-right mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${
            isOpen ? 'block' : 'hidden'
          } dark:bg-gray-700`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="account-menu"
        >
          <Link
            to={`/profiles/${userInfo.username}`}
            className="block px-4 py-2 hover:bg-gray-100 text-gray-700 dark:bg-slate-700 dark:hover:bg-gray-600 dark:text-white"
          >
            <div className="flex items-center">
              <CgProfile className="w-6 h-6 text-black flex font-extrabold hover:bg-gray-200 rounded-full dark:text-white hover:cursor-pointer dark:bg-gray-600 dark:hover:bg-gray-800" />
              <span className="px-4">{translation.profile}</span>
            </div>
          </Link>

          <div className="border-t border-gray-200 "></div>
          
          <NavLink
            to='/auth/change_password'
            className="flex space-x-2 px-4 py-2 hover:bg-gray-100 text-gray-700 dark:bg-slate-700 dark:hover:bg-gray-600 dark:text-white"
          >
            <span className="">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 0 1 1.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.559.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.894.149c-.424.07-.764.383-.929.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 0 1-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.398.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 0 1-.12-1.45l.527-.737c.25-.35.272-.806.108-1.204-.165-.397-.506-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.108-1.204l-.526-.738a1.125 1.125 0 0 1 .12-1.45l.773-.773a1.125 1.125 0 0 1 1.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894Z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
              </svg>
            </span>
            <span className="px-2">{translation.change_pass}</span>
          </NavLink>

          <div className="block px-4 py-2 hover:bg-gray-100 text-gray-700 dark:bg-slate-700 dark:hover:bg-gray-600 dark:text-white">
            <span className="flex items-center">
              <Switcher translation={translation}/>
            </span>
          </div>

          <div className="border-t border-gray-200 "></div>
          <span className='flex justify-center text-black dark:text-white px-2 border-b border-gray-200'>{translation.change_language}</span>
          <LanguageSelector />

          <div className="border-t border-gray-200"></div>

          <div className="block px-4 py-2 hover:bg-gray-100 text-gray-700 dark:bg-slate-700 dark:hover:bg-gray-600 dark:text-white">
            <span className="flex space-x-2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                <path fillRule="evenodd" d="M16.5 3.75a1.5 1.5 0 0 1 1.5 1.5v13.5a1.5 1.5 0 0 1-1.5 1.5h-6a1.5 1.5 0 0 1-1.5-1.5V15a.75.75 0 0 0-1.5 0v3.75a3 3 0 0 0 3 3h6a3 3 0 0 0 3-3V5.25a3 3 0 0 0-3-3h-6a3 3 0 0 0-3 3V9A.75.75 0 1 0 9 9V5.25a1.5 1.5 0 0 1 1.5-1.5h6ZM5.78 8.47a.75.75 0 0 0-1.06 0l-3 3a.75.75 0 0 0 0 1.06l3 3a.75.75 0 0 0 1.06-1.06l-1.72-1.72H15a.75.75 0 0 0 0-1.5H4.06l1.72-1.72a.75.75 0 0 0 0-1.06Z" clipRule="evenodd" />
              </svg>

              <LogoutButton translation={translation} />
            </span>
          </div>
        </div>
      ) : ( // Show login link for unauthenticated users
        <div
          className={`absolute rtl:left-0 ltr:right-0  origin-top-right mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${
            isOpen ? 'block' : 'hidden'
          } dark:bg-gray-700`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="account-menu"
        >
          <NavLink
            to="/auth/login"
            className="block px-4 py-2 hover:bg-gray-100 text-gray-700 dark:bg-slate-700 dark:hover:bg-gray-600 dark:text-white"
          >
            <span className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                />
              </svg>
              <span className="px-3">{translation.login}</span>
            </span>
          </NavLink>
        </div>
      )}
    </div>
  );
}