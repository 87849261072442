import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { multiFormatDateString } from '../utils/DateConvertor';
import "../assets/css/post.css";
import CommentList from './comments';

import { Link } from 'react-router-dom';
// buttons
import { BiUpvote, BiSolidUpvote } from "react-icons/bi";
import { BiDownvote, BiSolidDownvote } from "react-icons/bi";
import { FaRegBookmark, FaBookmark } from "react-icons/fa";
import '../assets/css/comments.css';

// Modals
import DonorsModal from '../modals/DonorsModal';
import ReportModal from '../modals/ReportModal';
import SupportSeekerModal from '../modals/SupportSeekerModal';
import ViewDocument from '../modals/ViewDocument';
import UpdatePostModal from '../modals/UpdatePostModal';

// Hooks
import useDonorsModal from '../hooks/useDonorsModal';
import useReportModal from '../hooks/useReportModal';
import usePostUpdateModalHook from '../hooks/useUpdatePostModal';

import useSupportSeekerModal from '../hooks/useSupportSeekerModal';
import useViewDocumentModal from '../hooks/useViewDocumentModal';
import MessagePopUp from './MessagePopUp';
import { Loading } from '../../components/Loading';

import { useGetPostDetailQuery, useGetPostDocFilesQuery, 
        useDownvoteMutation, useUpvoteMutation, useSaveMutation, useCommentMutation, useGetCommentsQuery } from '../../services/postApis';

import { useTranslation } from 'react-i18next';

const PostDetailView = () => {

  const { slug } = useParams();
  const { data, isError, error, isLoading } = useGetPostDetailQuery(slug);
  const { data: docData } = useGetPostDocFilesQuery(slug);
  const uDonorsModal = useDonorsModal();
  const uReportModal = useReportModal();
  const uUpdatePostModal  = usePostUpdateModalHook();

  const uSupportSeekerModal = useSupportSeekerModal();
  const uViewDocumentModal = useViewDocumentModal();

  const [upvote, setUpvotes] = useState(0)
  const [downvote, setDownvote] = useState(0)
  const [isUpvoted, setIsUpvoted] = useState(false);
  const [isDownvoted, setIsDownvoted] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [commentList, setCommentList] = useState([]);
  const { data: commentData } = useGetCommentsQuery(slug);
  const [commentCount, setCommentCount] = useState(0);
  const [isDonor, setIsDonor] = useState(false)
  const [isCreator, setIsCreator] = useState(false)
  const [messagePopup, setMessagePopup] = useState('')
  const [commentErrorMessage, setCommentErrorMessage] = useState('')

  const {t} = useTranslation('post')
  const translation = t('detail-page')
  const update_translation = t("post-update")




  const [selectedDoc, setSelectedDoc] = useState(null);
  const openDocumentModal = (docKey) => {
    setSelectedDoc(docData.documents[docKey]);
    uViewDocumentModal.open();
  };


  useEffect(() => {
    if (data) {
      setUpvotes(data.upvote_count);
      setDownvote(data.downvote_count);
      setIsUpvoted(data.is_upvoted);
      setIsDownvoted(data.is_downvoted);
      setIsSaved(data.is_saved);
      setCommentCount(data.comment_count);
  
      // Check if the user is a donor
      const isMyDonor = localStorage.getItem("is_donor") === "true";
  
      setIsCreator(data.is_creator_access)
      setIsDonor(isMyDonor || data.is_creator_access);
    }
  }, [data]);

  useEffect(() => {
    if (commentData) {
      setCommentList(commentData);
    }
  }, [commentData]);

  const [upvoteMutation] = useUpvoteMutation()
  const [downvoteMutation] = useDownvoteMutation()
  const [saveMutation] = useSaveMutation()
  const [commentMutation] = useCommentMutation()

  const handleUpvote = (event) => {
    event.stopPropagation();

    if (!isDonor) {
      setMessagePopup(`${translation.only_donor_upvote}`)
      return
    }

    if (!isUpvoted) {
      setUpvotes(upvote + 1)
      setIsUpvoted(true)
      if (isDownvoted) {
        setDownvote(downvote - 1)
        setIsDownvoted(false)
      };
      upvoteMutation(slug)
    } else {
      setUpvotes(upvote - 1)
      setIsUpvoted(false)
      upvoteMutation(slug)
    }
  }

  const handleDownvote = (event) => {
    event.stopPropagation();

    if (!isDonor) {
      setMessagePopup(`${translation.only_donor_downvote}`)
      return
    }

    if (!isDownvoted) {
      setDownvote(downvote + 1)
      setIsDownvoted(true)
      if (isUpvoted) {
        setUpvotes(upvote - 1)
        setIsUpvoted(false)
      };
      downvoteMutation(slug)
    } else {
      setDownvote(downvote - 1)
      setIsDownvoted(false)
      downvoteMutation(slug)
    }
  }

  const handleSave = (event) => {

    if (!isSaved) {
      setIsSaved(true)
      saveMutation(slug)
    } else {
      setIsSaved(false)
      saveMutation(slug)
    }
  }

  const handleSubmitComment = async (event) => {

    event.preventDefault();
    
    if (!isDonor) {
      setCommentErrorMessage(`${translation.only_donor_comment}`)
      return;
    }

    const formData = new FormData();

    if (event.target.body.value <= 2) {
      setCommentErrorMessage("Comment must be at least 2 characters");
      return;
    }

    formData.append("body", event.target.body.value);
    formData.append("slug", event.target.slug.value);

    try {
      const response = await commentMutation(formData);

      if (response?.error) {
        if (response?.error?.data?.detail) {
          setCommentErrorMessage(response?.error?.data?.detail);
        } else if (response?.error?.data?.error){
          setCommentErrorMessage(response?.error?.data?.error);
        }
      } 

      if (response?.data){
        setCommentErrorMessage('')
        setCommentList([...commentList, response.data]);
        setCommentCount(commentCount + 1);
        event.target.reset();

      }

    } catch (error) {
      setCommentErrorMessage('something went wrong')
    }
  };

  const handleOpenDocument = (docKey) => {
    if (!isDonor) {
      setMessagePopup(`${translation.only_donor_document}`);
      setTimeout(() => setMessagePopup(null), 2000); // Hide after 2 seconds
      return;
    }
  
    openDocumentModal(docKey);
  };
  

  if (isLoading) {
    return <div><Loading /></div>;
  }
  if (isError) return <div>Error: {error.message}</div>;
  
  return (
    
    <div className='bg-white dark:bg-gray-700 text-dark-1 dark:text-gray-100 mt-12 md:m-12 md:max-w-7xl p-5'>
      {messagePopup && <MessagePopUp message={messagePopup} />}

      <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
        <div className='border border-black'>
          {isDonor && data?.post_videos?.videos?.seeker_video? (
            <video className='detailVideo' controls controlsList="nodownload">
              <source src={data.post_videos.videos.seeker_video} type="video/mp4" />
            </video>
          ) : (
            <div className='flex justify-center items-center bg-gray-700 text-white opacity-80 m-10 border border-black p-10'>
              <span>
                {
                isCreator ? 
                  `${translation.video_in_process}`
                :
                  `${translation.only_donor}`
                }
              </span>
            </div>
          )}
        </div>
        <div className='border border-black'>
          {isDonor && data?.post_videos?.videos?.place_video? (
            <video className='detailVideo' controls controlsList="nodownload">
              <source src={data.post_videos.videos.place_video} type="video/mp4" />
            </video>
          ) : (
            <div className='flex justify-center items-center bg-gray-700 text-white opacity-80 m-10 border border-black p-10'>
              <span>
                {
                isCreator ? 
                  `${translation.video_in_process}`
                :
                  `${translation.only_donor}`
                }
              </span>
            </div>
          )}
        </div>
      </div>
      <hr className='m-3' />

      <div className='grid grid-cols-1 md:grid-cols-2 gap-5'>

        <div className='flex space-x-4 md:space-x-5'>
          <Link className='custom-link'
            to={`/profiles/${data.creator}`}>
            <p className='bg-blue-600 hover:bg-blue-300 text-white hover:text-black font-bold px-2 py-1 rounded-lg text-xs md:text-base'>{translation.creator} {data.creator}</p>
          </Link>

          <Link className='custom-link' onClick={handleUpvote}>
            {isUpvoted ? <BiSolidUpvote className='text-xl text-green-500' /> : <BiUpvote className='text-xl' />}
            <p className='text-xs font-bold text-blue-500 md:text-base'>{upvote}</p>
          </Link>
          <Link className='custom-link' onClick={handleDownvote}>
            {isDownvoted ? <BiSolidDownvote className='text-xl' /> : <BiDownvote className='text-xl' />}
            <p className='text-xs font-bold text-blue-500 md:text-base'>{downvote}</p>
          </Link>
          <Link className='custom-link' onClick={handleSave}>
            {isSaved ? <FaBookmark className='text-xl' /> : <FaRegBookmark className='text-xl' />}
          </Link>

        </div>

        <div className="flex text-xs space-x-2 justify-center m-3 md:text-base">
          
          {isCreator && (
              <button
                onClick={uUpdatePostModal.open}
                className="bg-blue-600 hover:bg-blue-300 text-white hover:text-black font-bold px-2 py-1 rounded-lg"
              >
                {update_translation.modal_name}
              </button>
          )}
          <button
            onClick={uDonorsModal.open}
            className=" bg-blue-600 hover:bg-blue-300 text-white hover:text-black font-bold py-1 px-2 rounded-lg"
          >
            {translation.donate} ({data.donors_count})
          </button>

          <button
            onClick={uReportModal.open} 
            className=" bg-blue-600 hover:bg-blue-300 text-white hover:text-black  font-bold py-1 px-2 rounded-lg">
            {translation.report} ({data.report_count})
          </button>

          <button
            onClick={uSupportSeekerModal.open} 
            className=" bg-blue-600 hover:bg-blue-300 text-white hover:text-black  font-bold py-1 px-2 rounded-lg">
            {translation.support_btn}
          </button>
        </div>

        {/* Render the Donate Modal */}
        <DonorsModal slug={data.slug} />
        <ReportModal slug={data.slug} isDonor={isDonor} />
        <SupportSeekerModal slug={data.slug} isDonor={isDonor}/>
        <UpdatePostModal slug={data.slug} translation={update_translation} />


      </div>
      
      <hr className='m-3' />

      
        
      <div className="space-y-3 py-4">
        <h2 className="text-2xl font-semibold">{translation.detail_heading}</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 font-semibold text-white py-1 px-2 rounded-lg mx-2 hover:text-black hover"
            >
                {translation.seeker_details.name}: {data.seeker}
            </div>

            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
            >
                {translation.seeker_details.need}: {data.need}
            </div>

            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
            >
                {translation.seeker_details.post_type}: {data.post_type}
            </div>

            
            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
            >
              {translation.seeker_details.created_at}: {multiFormatDateString(data.created)}
            </div>
            
            {data?.post_videos?.videos?.seeker_video_prediction && (
              <div 
                className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
              >
                Seeker video prediction: {data.post_videos.videos.seeker_video_prediction}%
              </div>
              )
            }
            
            {data?.post_videos?.videos?.place_video_prediction && (
              <div 
                className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
              >
                Place video prediction: {data.post_videos.videos.place_video_prediction}%
              </div>
            )}

            {data?.human_verification > 0 && (
              <div 
                className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
              >
                  {translation.seeker_details.human_verification}: {data.human_verification}%
              </div>
            )}
            

            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
            >
                {translation.seeker_details.total_need}: {data.needed_money}
            </div>

            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
            >
                {translation.seeker_details.total_donated}: {data.paid}
            </div>

            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
            >
                {translation.seeker_details.views}: {data.views.length}
            </div>

            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
            >
              {translation.seeker_details.phone_no}: {data.phone_number}
            </div>

            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
            >
              {translation.seeker_details.seeker_cnic}: {data.seeker_cnic}
            </div>
            
            
            <div 
              className="flex items-center bg-slate-600 hover:bg-slate-300 text-white font-semibold py-1 px-2 rounded-lg mx-2 hover:text-black hover"
            >
                {translation.seeker_details.address}: {data.address}
            </div>
        </div>
      </div>

      <hr className='m-3' />
      
      <div className="space-y-3 py-4">
        <h2 className="text-2xl font-semibold">{translation.documents}</h2>

        {messagePopup && <MessagePopUp message={messagePopup} />}
        
        {!docData && "Seeker did not provide any proof documents"}
        
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          {docData &&
            docData.documents &&
            Object.keys(docData.documents).map((docKey) => (
              <button
                key={docKey}
                onClick={() => handleOpenDocument(docKey)}
                className="flex items-center bg-blue-600 hover:bg-slate-300 text-white font-bold py-1 px-2 rounded-lg mx-2 hover:text-black truncate"
              >
                {docKey.replace(/_/g, " ")}
              </button>
            ))}
        </div>

        {/* Render the modal only when it's open */}
        {uViewDocumentModal.isOpen && <ViewDocument url={selectedDoc} />}
      </div>
      

      <div className='space-y-3 py-4'>
        <div className='text-2xl font-bold'>{translation.discription}</div>
        <p className='text-dark-1 dark:text-white'>{data.description}</p>
      </div>

      <hr className='m-3' />
      
      <div className='py-4 bg-white dark:bg-gray-700 '>
        <p className='text-2xl font-bold'>{commentCount} {translation.comments}</p>

        <form onSubmit={handleSubmitComment}>
          <div className="flex justify-end space-x-2">
            <textarea 
              id="comment"
              name="body"
              rows="1"
              className="w-full px-4 text-black py-3 border border-gray-300 dark:border-gray-600 rounded-lg resize bg-blue-50 hover:bg-blue-100"
              placeholder={translation.add_a_comment}
            ></textarea>

            <input type='hidden' value={data.slug} name='slug' />
            <button type="submit" className="bg-blue-500 text-white font-bold py-1 px-5 rounded-lg text-sm">
              {translation.comment_sub}
            </button>
          </div>
          {commentErrorMessage && <p>{commentErrorMessage}</p>}
        </form>

        {commentList && commentList.length > 0 ? (
            [...commentList].reverse().map(comment => (
                <CommentList key={comment.id} comment={comment} />
            ))
        ) : (
            <p>{translation.no_comment_yet}</p>
        )}


      </div>

    </div>
  );
}

export default PostDetailView
