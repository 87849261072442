import React from "react";
import JoinDonorCommunity from "../modals/JoinDonorCom";
import useJoinDonorCom from "../hooks/useJoinDonorCom";
import {useTranslation} from "react-i18next";

const JoinDonorCommunityPage = ({ donorCount }) => {
  const uJoinDonorCom = useJoinDonorCom();
  
  const {t} = useTranslation('joinDonor');
  const benefits = t("benefits", { returnObjects: true });
  const main = t('main')
  const requirements = t('requirements')
  const wchannel = t('MyDonorDashboard')
  

  return (
    <div className="flex items-center justify-center p-1 md:p-5 from-blue-50 to-blue-100 dark:from-gray-900 dark:to-gray-800 mt-10">
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-2xl p-3 md:p-10 max-w-4xl w-full text-center">
        <h1 className="text-4xl font-extrabold text-gray-900 dark:text-white mb-4">
          {main.heading}
        </h1>
        
        <p className="text-lg text-gray-600 dark:text-gray-400 mt-3">
          {main.sub_heading}
        </p>

        {/* Benefits Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-8">
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="bg-blue-100 dark:bg-gray-700 p-2 rounded-lg flex items-start space-x-2 hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1 text-justify"
            >
              <span className="text-blue-600 dark:text-blue-400 text-lg md:text-2xl ">✔️</span>
              <p className= "rtl:text-right ltr:text-left text-gray-800 dark:text-gray-200 text-left px-1 text-base md:text-lg" >{benefit}</p>
            </div>
          ))}
        </div>

        <div className="mb-1 bg-gray-50 dark:bg-gray-700 rounded-xl text-left">
          <div className="bg-blue-50 dark:bg-gray-600 border-l-4 border-blue-500 p-4 mb-6 rounded">
            <div className="flex items-center mb-2">
              <svg className="w-5 h-5 text-blue-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <h3 className="rtl:text-right ltr:text-left font-bold text-gray-800 dark:text-white" >{main.joining_req}</h3>
            </div>
            <ul className="ml-6 list-disc text-gray-600 dark:text-gray-300 rtl:text-right ltr:text-left ">
              {requirements.map((req, index) => (
                <li key={index}>{req}</li>
              ))}
            </ul>
          </div>

        </div>

        
        <button
          className="px-8 py-3 bg-blue-600 text-white rounded-lg font-semibold hover:bg-blue-700 transition-all duration-300 transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 shadow-md"
          onClick={uJoinDonorCom.open}
        >
          {main.join}
        </button>


        <JoinDonorCommunity />

        <hr className="mt-10"/>
        <div className="m-10 max-w-md mx-auto bg-gradient-to-r  from-green-50 to-emerald-50 dark:from-gray-800 dark:to-gray-900 rounded-xl shadow-sm hover:shadow-lg transition-all duration-300 border border-green-100 dark:border-gray-700">
          <div className="p-5">
            <div className="flex items-start">
              <div className="flex flex-col items-center">
                <h3 className="font-semibold text-xl text-gray-800 dark:text-white">{wchannel.join_wc}</h3>
                <p className="mt-3 text-gray-600 dark:text-gray-300 text-base text-justify">{wchannel.join_wc_message}</p>
                
                <div className="mt-3 flex flex-col sm:flex-row sm:items-center gap-3">
                  <button
                    onClick={() => window.open('https://whatsapp.com/channel/0029Vb4L7Cz0LKZK3dhU9l09', '_blank')}
                    className="flex items-center justify-center gap-2 px-4 py-2.5 bg-green-500 hover:bg-green-600 text-white font-medium rounded-lg transition-colors w-full sm:w-auto"
                  >
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
                      <path d="M1.101 21.757L23.8 12.028 1.101 2.3l.011 7.912 13.623 1.816-13.623 1.817-.011 7.912z"></path>
                    </svg>
                    {wchannel.join_now}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>

    
  );
};

export default JoinDonorCommunityPage;