import Modal from "./Modal";
import useUpdateProfileDetails from "../hooks/useUpdateProfileDetailsModal";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";

const UpdateProfileDetailsModal = ({ username }) => {
  const useUpdateProfileModal = useUpdateProfileDetails();
  const {t} = useTranslation('profile')
  const translation = t('profile-edit')

  const [postDataErrorMessage, setPostDataErrorMessage] = useState('');
  const [postUpdateSuccessMessage, setPostUpdateSuccessMessage] = useState('');
  
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    fullname: '',
    // username: '',
    religion: '',
    dob: '',
    introduction: '',
    address: '',
    phone_number: '',
  });

  const [originalData, setOriginalData] = useState({
    fullname: '',
    // username: '',
    religion: '',
    dob: '',
    introduction: '',
    address: '',
    phone_number: '',
  });


  useEffect(() => {
    const fetchProfileData = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_DOMAIN}/user/update-user-profile-details/`,
          {
            params: { username },
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
            },
          }
        );
        if (response.status === 200) {
          setFormData(response.data);
          setOriginalData(response.data); // Store the original data
        }
      } catch (error) {
        // setErrorMessage('Failed to fetch profile data');
      } finally {
        setIsLoading(false);
      }
    };
  
    if (useUpdateProfileModal.isOpen) {
      fetchProfileData();
    }
  }, [useUpdateProfileModal.isOpen, username]);

  const handlePostDataChange = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === 'checkbox' ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: val,
    }));
  };

  const SubmitPostData = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Compare originalData and formData, only keep changed fields
    const updatedFields = Object.keys(formData).reduce((acc, key) => {
      if (formData[key] !== originalData[key]) {
        acc[key] = formData[key];
      }
      return acc;
    }, {});


    if (Object.keys(updatedFields).length === 0) {
      setPostDataErrorMessage("No changes detected.");
      setIsLoading(false);
      return;
    }

    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BACKEND_DOMAIN}/user/update-user-profile-details/`,
        JSON.stringify(updatedFields),
        config
      );
    
      if (response.status === 200) {
        setIsLoading(false);
        setPostDataErrorMessage('');
        setPostUpdateSuccessMessage('Successfully updated');
        
        // Use setTimeout to delay modal closing and reset messages
        setTimeout(() => {
          useUpdateProfileModal.close();
          setPostUpdateSuccessMessage('');
        }, 3000);
      }
    } catch (error) {
      setIsLoading(false);
    
      if (error.response && error.response.data) {
        let errorData = error.response.data;
    
        if (errorData?.error && typeof errorData?.error === "object") {
          if (Array.isArray(errorData.error?.error)) {
            setPostDataErrorMessage(errorData.error.error[0]);
          } else if (errorData.error.fullname?.length) {
            setPostDataErrorMessage(`${translation.fullname}` + errorData.error.fullname[0]);
          } else if (errorData.error.address?.length) {
            setPostDataErrorMessage(`${translation.address}`  + errorData.error.address[0]);
          } else if (errorData.error.phone_number?.length) {
            setPostDataErrorMessage(`${translation.phone_number}`+ errorData.error.phone_number[0]);
          } else if (errorData.error.dob?.length) {
            setPostDataErrorMessage(`${translation.dob}` + errorData.error.dob[0]);
          } else if (errorData.error.religion?.length) {
            setPostDataErrorMessage(`${translation.religion}`  + errorData.error.religion[0]);
          } else if (errorData.error.introduction?.length) {
            setPostDataErrorMessage(`${translation.introduction}`+ errorData.error.introduction[0]);
          } else {
            setPostDataErrorMessage("An unknown validation error occurred.");
          }
        } else if (typeof errorData?.error === "string") {
          setPostDataErrorMessage(errorData.error);
        } else {
          setPostDataErrorMessage("An unknown error occurred.");
        }
      } else {
        setPostDataErrorMessage("An unexpected error occurred. Please try again.");
      }
    }
  };

  const content = (
    <>
      <div className="pt-2 pb-5 space-y-5">
        <div className="flex flex-col">
          <label htmlFor="fullname" className="block font-semibold mb-2 dark:text-white">{translation.fullname}</label>
          <input
            type="text"
            id="fullname"
            name="fullname"
            placeholder='Bilal Ahmed'
            value={formData.fullname}
            onChange={handlePostDataChange}
            className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
            required
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="address" className="block font-semibold mb-2 dark:text-white">{translation.address}</label>
          <input
            type="text"
            id="address"
            name="address"
            placeholder='Address: C.1-167, North Karachi,'
            value={formData.address}
            onChange={handlePostDataChange}
            className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
            required
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="phone_number" className="block font-semibold mb-2 dark:text-white">{translation.phone_number}</label>
          <input
            type="tel"
            id="phone_number"
            name="phone_number"
            placeholder='03xx-xxxxxx21'
            value={formData.phone_number}
            onKeyDown={(e) => {
              if (!/[0-9]/.test(e.key) && 
                  !["Backspace", "Delete", "Tab", "Enter", "ArrowLeft", "ArrowRight"].includes(e.key)) {
                e.preventDefault();
              }
            }}
            min="0"
            step="1"
            inputMode="numeric"
            onChange={handlePostDataChange}
            className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
            required
            maxLength={11}
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="dob" className="block font-semibold mb-2 dark:text-white">{translation.dob}</label>
          <input
            type="date"
            id="dob"
            name="dob"
            value={formData.dob}
            onChange={handlePostDataChange}
            className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400 dark:bg-gray-800 dark:text-white"
            required
            min={new Date(new Date().setFullYear(new Date().getFullYear() - 90)).toISOString().split("T")[0]}
            max={new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toISOString().split("T")[0]}
          />
        </div>

        <div className="flex flex-col">
          <label htmlFor="religion" className="block font-semibold mb-2 dark:text-white">{translation.religion}</label>
          <select
            id="religion"
            name="religion"
            value={formData.religion}
            onChange={handlePostDataChange}
            className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
            required
          >
            <option value="">{translation.religion_details.select}</option>
            <option value="Islam">{translation.religion_details.islam}</option>
            <option value="Hindu">{translation.religion_details.hindu}</option>
            <option value="Christian">{translation.religion_details.christian}</option>
          </select>
        </div>

        <div className="flex flex-col">
          <label htmlFor="introduction" className="block font-semibold mb-2 dark:text-white">{translation.introduction}</label>
          <textarea
            id="introduction"
            name="introduction"
            value={formData.introduction}
            onChange={handlePostDataChange}
            className="block w-full p-2 border border-gray-300 rounded focus:outline-none focus:border-blue-400"
            required
          />
        </div>
      </div>

      <div className="text-center">
        {isLoading ? (
          <div className="inline-block loading-icon">
            <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 dark:border-stone-100" />
          </div>
        ) : (
          <button
            onClick={SubmitPostData}
            className="w-full rounded-md bg-blue-500 text-white font-extrabold px-4 py-2 hover:bg-blue-600"
          >
            {translation.submit}
          </button>
        )}

        {postDataErrorMessage && <div style={{ color: 'red', fontWeight: 'bold' }}>{postDataErrorMessage}</div>}

        
        {postUpdateSuccessMessage && <div style={{ color: 'green', fontWeight: 'bold' }}>{postUpdateSuccessMessage}</div>}
      </div>
    </>
  );

  return (
    <Modal
      isOpen={useUpdateProfileModal.isOpen}
      close={useUpdateProfileModal.close}
      label={translation.update_profile_modal}
      content={content}
    />
  );
};

export default UpdateProfileDetailsModal;