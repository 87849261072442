import { useState } from 'react';
import { useSendPasswordResetEmailMutation } from "../../services/userAuthApi";

const SendPasswordResetEmail = () => {
  const [serverError, setServerError] = useState({})
  const [serverMsg, setServerMsg] = useState({})
  const [sendPasswordResetEmail, { isLoading }] = useSendPasswordResetEmailMutation()

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const actualData = {
      email: data.get('email'),
    }

    const res = await sendPasswordResetEmail(actualData)
    if (res.error) {
      setServerMsg({})
      setServerError(res.error.data?.errors || {})

    }
    if (res.data) {
      setServerError({})
      setServerMsg(res.data)
      document.getElementById('password-reset-email-form').reset()
    }

  }


  return (
    <div className="flex items-center justify-center h-screen bg-white-100 p-3">
      <div className="max-w-md p-6 rounded-lg shadow-md bg-white dark:bg-gray-700">
        <h1 className="text-2xl mb-4 font-bold  text-black dark:text-stone-50 ">Forgot Password?</h1>
        <form id="password-reset-email-form" onSubmit={handleSubmit}>
          <div className="mt-4 font-bold  text-black dark:text-stone-50 ">Kindly Enter the Email Address tied to your account, we would help you reset your password
          <div className="mb-4 mt-4 font-bold  text-black dark:text-stone-50 ">
            <label htmlFor="email" className="block mb-2 font-bold  text-black dark:text-stone-50 ">Email Address</label>
            <input type="email" name="email" id="email" placeholder="abc@example.com" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
          </div>
          </div>

          {serverError.email && <p className="text-red text-base mb-2 font-bold">{serverError.email}</p>}
          {serverError.non_field_errors	 && <p className="text-red text-base mb-2 font-bold">{serverError.non_field_errors}</p>}
          
          <button type="submit" className="w-full bg-blue-500 hover:bg-red-1000 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" disabled={isLoading} >
            submit
          </button>
          {serverMsg.msg && <p className="text-green-400 font-bold text-base m-2">{serverMsg.msg}</p>}
        </form>
      </div>
    </div>
  );
};

export default SendPasswordResetEmail;
