import React from 'react';
import App from './App.js';
import "./i18n/i18n.js";
import reportWebVitals from './reportWebVitals.js';
import { Provider } from 'react-redux'
import { store } from './storage/store.js'
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Loading } from './components/Loading.js';

import { PostHogProvider} from 'posthog-js/react'
const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

const queryClient = new QueryClient();
const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PostHogProvider 
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    >
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <React.Suspense fallback={<Loading />}>
          <App />
        </React.Suspense>
      </Provider>
    </QueryClientProvider>
    </PostHogProvider>
  </React.StrictMode>
);


reportWebVitals();
