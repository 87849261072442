// LanguageSelector.jsx
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

const languages = [
  { code: "en", lang: "English" },
  { code: "ur", lang: "اردو" },
  { code: "ar", lang: "العربية" },
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.dir = i18n.dir();
  }, [i18n, i18n.language]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="w-full">
      {languages.map((lng) => (
        <div
          key={lng.code}
          className={`flex px-4 py-2 hover:bg-gray-100 text-gray-700 dark:bg-slate-700 dark:hover:bg-gray-600 dark:text-white hover:cursor-pointer ${
            lng.code === i18n.language
              ? "bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-white"
              : "text-gray-700 hover:bg-gray-100 dark:text-white dark:hover:bg-gray-600"
          }`}
          onClick={() => changeLanguage(lng.code)}
        > 
          <span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
            </svg>

          </span>
          <span className="px-4">
            {lng.lang}
            {lng.code === i18n.language && (
              <span className="px-2">✓</span> // Add checkmark for active language
            )}
          </span>
        </div>
      ))}
    </div>
  );
};

export default LanguageSelector;