import { useState } from 'react';
import { useChangeUserPasswordMutation } from '../../services/userAuthApi';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const ChangePassword = () => {
  const [serverError, setServerError] = useState({});
  const [serverMsg, setServerMsg] = useState({});
  const [changeUserPassword, { isLoading }] = useChangeUserPasswordMutation();
  const navigate = useNavigate();
  const {t} = useTranslation("auth")
  const translation = t('auth_tran')


  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const actualData = {
      password: data.get('password'),
      password2: data.get('password2'),
    };
    const res = await changeUserPassword({ actualData });
    if (res.error) {
      setServerMsg({});
      setServerError(res.error.data?.errors || {});
    }
    if (res.data) {
      setServerError({});
      setServerMsg(res.data);
      document.getElementById('password-change-form').reset();

      setTimeout(() => {
        navigate('/auth/login');
        // localStorage.removeItem('access_token')
        // localStorage.removeItem('refresh_token')
      }, 3000);

    }
  };

  return (
    <div className="container flex items-center justify-center mt-4">
        <form className="bg-white dark:bg-gray-700 shadow-md rounded px-5 pt-6 pb-8 overflow-y-auto mt-20"   onSubmit={handleSubmit} noValidate id="password-change-form">
          <h2 className="text-2xl font-bold mb-6 text-black dark:text-stone-50">{translation.heading}</h2>
          <div className="mb-4 mt-4">
            <label htmlFor="current-password" className=" text-black font-bold mb-2  dark:text-stone-50">
              {translation.cur_pass}
            </label>
            <input
              id="current-password"
              name="current-password"
              type="password"
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-black leading-tight focus:outline-none focus:shadow-outline"
            />
            {serverError.current_password && (
              <p className="text-base text-red font-bold mb-2">{serverError.current_password[0]}</p>

            )}
          </div>
          <div className='mb-4 mt-4'>
            <label htmlFor="password" className=" text-gray-700 font-bold mb-2  dark:text-stone-50">
              {translation.new_pass}
              
            </label>
            <input
              id="password"
              name="password"
              type="password"
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {serverError.password && (
              <p className="text-base text-red font-bold mb-2">{serverError.password[0]}</p>
            )}
          </div>
          <div className="mb-4 mt-4">
            <label htmlFor="password2" className="text-gray-700 font-bold mb-2  dark:text-stone-50">
              {translation.comf_new_pass}
            </label>
            <input
              id="password2"
              name="password2"
              type="password"
              required
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            {serverError.password2 && (
              <p className="text-base text-red font-bold mb-2">{serverError.password2[0]}</p>
            )}
          </div>

          <div className="text-center">
            {isLoading ? (
              <div className="inline-block">
                <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900 dark:border-stone-100" />
              </div>
            ) : (
              <button
                type="submit"
                className="w-full bg-blue-500 hover:bg-red-1000 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                {translation.update}
              </button>
            )}
          </div>

          {serverError.non_field_errors && (
            <div className="text-base text-red font-bold mb-2">{serverError.non_field_errors[0]}</div>
          )}

          {serverMsg.msg && (
            <div className="text-green-500 text-base mt-2">{serverMsg.msg}</div>
          )}

        </form>
      {/* </div> */}
    </div>
  );
};

export default ChangePassword;
